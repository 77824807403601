body.theme-pink-dark toolbar .mat-toolbar.top_header {
    background: inherit !important;
}

body.theme-pink-dark toolbar section.ribbon-container.ng-star-inserted mat-card {
    background: #424242 !important;
}

body.theme-pink-dark toolbar .mat-tab-body section.ribbon-container {
    background: #424242 !important;
}

body.theme-pink-dark .accent {
    background-color: #3b0a79 !important;
}

body.theme-pink-dark .sticky_search_div {
    background-color: transparent !important;
}

body.theme-pink-dark .top_right_action,
body.theme-pink-dark .top_right_action_billing_position {
    background-color: #424242 !important;
    // right: 10px;
}

body.theme-pink-dark .list_sidebar_right_child {
    background-color: #424242 !important;
}

body.theme-pink-dark .p-24.side_header {
    background-color: #424242 !important;
}

body.theme-pink-dark .mat-list-item-content {
    color: #fff;
}

body.theme-pink-dark .row-text-colore {
    background-color: rgb(217, 217, 217) !important;
}

body.theme-pink-dark .mat-input-element {
    caret-color: #fff !important;
}

body.theme-pink-dark .mat-form-field.mat-focused .mat-form-field-label {
    color: #fff !important;
}

body.theme-pink-dark .tbl_fix_header {
    background-color: #424242 !important;
}

body.theme-pink-dark .mat-table {
    background: #424242 !important;
}

body.theme-pink-dark.body.theme-yellow-light .mat-table {
    background: #424242 !important;
}

body.theme-pink-dark .grid-overlay-msg {
    color: #fff;
}

body.theme-pink-dark button.disabled-click {
    color: rgba(255, 255, 255, 0.4) !important;
}

// body.theme-pink-dark .mat-flat-button.mat-primary,
// body.theme-pink-dark .mat-raised-button.mat-primary {
//     background-color: #3b0a79 !important;
// }
body.theme-pink-dark .mat-flat-button.mat-accent,
body.theme-pink-dark .mat-raised-button.mat-accent {
    background-color: #3b0a79 !important;
}

body.theme-pink-dark .add_edit_contact_main {
    background-color: #424242;
}

body.theme-pink-dark .mat-dialog-container {
    background: transparent;
    box-shadow: none;
}

body.theme-pink-dark .popup_main_div {
    background-color: #424242;
}

body.theme-pink-dark .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
body.theme-pink-dark .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: #3b0a79;
}

body.theme-pink-dark .example-list {
    background-color: #424242;
}

body.theme-pink-dark .contact_right_action {
    background-color: #424242;
}

body.theme-pink-dark .top_right_action_billing_one {
    background-color: #424242;
}

body.theme-pink-dark .mat-tab-group.mat-primary .mat-ink-bar,
body.theme-pink-dark .mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: #fff !important;
}

body.theme-pink-dark .mail-content a {
    color: #fff;
}

body.theme-pink-dark .cal-month-view .cal-day-cell.cal-today {
    background-color: #3b0a79;
}

body.theme-pink-dark .list_sidebar_padding {
    background-color: #424242;
    border-left: none;
}

body.theme-pink-dark .favourite_form_div mat-list.mat-list.mat-list-base.ng-star-inserted {
    // background-color: transparent;
    background-color: #fff;
}

body.theme-pink-dark .favourite_form_div .mat-list-item-content {
    color: #000;
}

body.theme-pink-dark .mat-raised-button.mat-primary {
    background-color: #999999;
}

body.theme-pink-dark button.cancel.mat-button {
    border: 1px solid #999;
    background-color: #303540;
}

body.theme-pink-dark toolbar fuse-sidebar.right-positioned {
    background: #303030 !important;
}

body.theme-pink-dark .row-text-colore mat-cell {
    color: #000 !important;
}

body.theme-pink-dark .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: #3b0a79 !important;
}

body.theme-pink-dark .mat-radio-button.mat-accent .mat-radio-inner-circle {
    background-color: #3b0a79 !important;
}

body.theme-pink-dark .total_Val {
    color: #fff;
}

body.theme-pink-dark .mat-row.row-text-colore td {
    // color: #fff !important;
    color: #000 !important;
}

body.theme-pink-dark .row-text-colore .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
body.theme-pink-dark .row-text-colore .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: #fff !important;
}

body.theme-pink-dark mat-row .special-group-header {
    color: #fff !important;
}

body.theme-pink-dark .mat-list-base .mat-subheader {
    color: rgba(255, 255, 255, 0.7) !important;
}

body.theme-pink-dark .row-text-colore .mat-typography p {
    color: #fff !important;
}

body.theme-pink-dark .e-grid .e-gridcontent {
    background-color: #424242 !important;
}

body.theme-pink-dark .e-grid .e-rowcell:not(.e-editedbatchcell),
body.theme-pink-dark .e-grid .e-detailrowcollapse:not(.e-editedbatchcell),
body.theme-pink-dark .e-grid .e-detailrowexpand:not(.e-editedbatchcell),
body.theme-pink-dark .e-grid .e-gridcontent .e-rowdragdrop:not(.e-editedbatchcell),
body.theme-pink-dark .e-grid .e-emptyrow:not(.e-editedbatchcell) {
    color: #fff;
}

body.theme-pink-dark .e-treegrid .e-treegridexpand,
body.theme-pink-dark .e-treegrid .e-treegridcollapse {
    color: #fff;
}

body.theme-pink-dark .e-treegrid .e-active .e-treegridexpand,
body.theme-pink-dark .e-treegrid .e-active .e-treegridcollapse {
    color: #000;
}

body.theme-pink-dark .readonly_apply .mat-form-field-wrapper,
body.theme-pink-dark .readonly_apply .mat-input {
    background-color: transparent;
    cursor: not-allowed;
}

body.theme-pink-dark .disabled-colore[disabled],
body.theme-pink-dark .disabled-colore:disabled {
    color: rgba(255, 255, 255, 0.3) !important;
}

body.theme-pink-dark button.cancel.mat-button {
    border: 1px solid #999 !important;
    background-color: #777 !important;
}

body.theme-pink-dark .border_div a {
    color: #3b0a79 !important;
}

body.theme-pink-dark fuse-sidebar {
    background: #303030 !important;
}

body.theme-pink-dark .theme-options-panel form .group h2 {
    background: #303030 !important;
    color: rgba(255, 255, 255, 0.54) !important;
}

body.theme-pink-dark .theme-options-panel .mat-radio-button {
    margin-left: 0 !important;
}

body.theme-pink-dark .mat-form-field-appearance-outline .mat-form-field-outline-start,
body.theme-pink-dark .mat-form-field-appearance-outline .mat-form-field-outline-gap,
body.theme-pink-dark .mat-form-field-appearance-outline .mat-form-field-outline-end {
    border-color: #fff;
}


body.theme-pink-dark .has-error.mat-form-field-appearance-outline .mat-form-field-outline-start,
body.theme-pink-dark .has-error.mat-form-field-appearance-outline .mat-form-field-outline-gap,
body.theme-pink-dark .has-error.mat-form-field-appearance-outline .mat-form-field-outline-end {
    border-color: red;
}
body.theme-pink-dark .has-error.mat-form-field-appearance-outline.mat-form-field-should-float .mat-form-field-outline-gap,
body.theme-pink-dark .has-error.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-gap {
    border-top-color: transparent;
}

body.theme-pink-dark .mat-tab-body h3 {
    background-color: #303030 !important;
}

// body.theme-pink-dark .cal-month-view .cal-day-badge {
//     background-color: #3b0a79 !important;
// }

body.theme-pink-dark .cal-month-view .cal-days .cal-cell-row .cal-day-cell.cal-weekend .cal-day-number {
    color: #3b0a79 !important;
}

body.theme-pink-dark .cal-week-view {
    background-color: #424242 !important;
}

body.theme-pink-dark .cal-week-view .cal-hour-odd {
    background-color: #303030 !important;
}

body.theme-pink-dark .cal-week-view .cal-day-headers .cal-header.cal-weekend span {
    color: #3b0a79 !important;
}

body.theme-pink-dark a.link_click {
    color: #fff !important;
}

body.theme-pink-dark .ngx-charts {
    fill: #fff !important;
}

body.theme-pink-dark .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    color: #fff !important;
}

body.theme-pink-dark .e-grid .e-headercell,
body.theme-pink-dark .e-grid .e-detailheadercell {
    background-color: #424242;
    color: #fff;
}

body.theme-pink-dark .is_green.e-treegrid td.e-active {
    background-color: rgb(217, 217, 217) !important;
}

body.theme-pink-dark .e-grid,
body.theme-pink-dark .e-grid .e-content,
body.theme-pink-dark .e-grid .e-table {
    background-color: #424242 !important;
}

body.theme-pink-dark .mat-slide-toggle-thumb {
    background-color: #bdbdbd !important;
}

body.theme-pink-dark .mat-slide-toggle-bar {
    background-color: rgba(255, 255, 255, 0.5) !important;
}

body.theme-pink-dark .mat-progress-spinner circle,
body.theme-pink-dark .mat-spinner circle {
    stroke: #fff !important;
}

body.theme-pink-dark .theme-options-panel form .group {
    border: 1px solid rgba(255, 255, 255, 0.22) !important;
}

body.theme-pink-dark .mat-dialog-container .mat-card:not([class*='mat-elevation-z']) {
    border: 1px solid #fff !important;
}

body.theme-pink-dark .btm_email_margin .mat-stroked-button {
    border: 1px solid #3b0a79 !important;
}

body.theme-pink-dark .row-text-colore span {
    color: #000;
}

body.theme-pink-dark .row-text-colore span.has-red {
    color: red;
}


body.theme-pink-dark .cal-week-view .cal-time-events .cal-day-columns:not(.cal-resize-active) .cal-hour-segment:hover {
    background-color: #999 !important;
}

body.theme-pink-dark .mat-calendar-body-selected {
    background-color: #3b0a79 !important;
}

body.theme-pink-dark .precedents-listing {
    background-color: #424242 !important;
}

body.theme-pink-dark .mat-header-row .mat-header-cell:first-child {
    border-left: none !important;
}

body.theme-pink-dark .mat-row .mat-cell:first-child {
    border-left: none !important;
}

body.theme-pink-dark .precedents-header {
    // background-color: #3b0a79 !important;
}

body.theme-pink-dark .court-header {
    // background-color: #3b0a79 !important;
}

img.seticon {
    margin-top: 0 !important;
    width: 20px !important;
    float: right !important;
}

.mat-form-field mat-icon.set_icon {
    font-size: 22px !important;
}

body.theme-pink-dark .e-treegrid td.e-active label {
    color: #000;
}

body.theme-pink-dark .ck.ck-editor__main>.ck-editor__editable {
    background: none !important;
}

body.theme-pink-dark .readonly_apply .mat-form-field-label,
body.theme-pink-dark .gray_out_for_poup .mat-form-field-label,
body.theme-pink-dark .gray_out .mat-form-field-label {
    color: rgba(0, 0, 0, 0.7);
}

body.theme-pink-dark .readonly_apply .mat-input-element,
body.theme-pink-dark .gray_out_for_poup .mat-input-element,
body.theme-pink-dark .gray_out .mat-input-element {
    color: rgba(0, 0, 0, 0.7);
}

body.theme-pink-dark .search-wrapper .secondary-text {
    color: rgba(0, 0, 0, 0.7) !important;
}

body.theme-pink-dark .settlment_height .table_overflow_auto {
    background-color: #424242 !important;
}

body.theme-pink-dark .settlment_height.example-containerdata {
    background-color: #424242 !important;
}

body.theme-pink-dark .cal-month-view .cal-day-badge {
    background: #4d2184 !important;
}

body.theme-default .cal-month-view .cal-day-appoint .cal-day-appoint-left,
body.theme-pink-dark .cal-month-view .cal-day-appoint .cal-day-appoint-left {
    background: #4d2184 !important;
}

body.theme-default .cal-month-view .cal-day-appoint .cal-day-appoint-right,
body.theme-pink-dark .cal-month-view .cal-day-appoint .cal-day-appoint-right {
    background: rgba(77, 33, 132, 0.4) !important;
}

body.theme-pink-dark .row-text-colore td {
    color: #000;
}

body.theme-pink-dark .mat-primary .mat-pseudo-checkbox-checked,
body.theme-pink-dark .mat-primary .mat-pseudo-checkbox-indeterminate {
    background: #4d2184 !important;
}

body.theme-pink-dark .mat-form-field.mat-focused .mat-form-field-ripple {
    background-color: #fff;
}

body.theme-pink-dark .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
    color: rgba(0, 0, 0, 0.5) !important;
}

body.theme-pink-dark .mat-input-element:disabled,
body.theme-pink-dark .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
    color: rgba(0, 0, 0, 0.6) !important;
}

body.theme-pink-dark .mat-icon-button[disabled][disabled] {
    color: rgba(0, 0, 0, 0.3) !important;
}

body.theme-pink-dark .court-listing {
    background: #424242;
}

body.theme-default .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background: rgba(77, 33, 132, 0.4) !important;
}
body.theme-pink-dark .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background: #4d2184 !important;
}
body.theme-pink-dark .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background: rgba(163, 142, 189, 0.79) !important;
}
body.theme-pink-dark .mat-badge-warn .mat-badge-content {
    background: #4d2184;
}

body.theme-pink-dark .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: #4d2184 !important;
}

body.theme-pink-dark .mat-paginator-container .mat-icon-button[disabled][disabled] {
    color: rgba(255, 255, 255, 0.4) !important;
}

body.theme-pink-dark .mat-select-disabled .mat-select-value {
    color: rgba(0, 0, 0, 0.5);
}

body.theme-pink-dark .cal-event-actions a {
    color: #4d2184;
}

body.theme-pink-dark .mat-progress-bar-fill::after {
    background-color: #4d2184 !important;
}

body.theme-pink-dark .mat-progress-bar-buffer {
    background-color: #8770a5 !important;
}

body.theme-pink-dark .row-text-colore td {
    color: #000 !important;
}

body.theme-pink-dark .mat-table .mat-header-cell {
    color: rgba(255, 255, 255, 0.7) !important;
    background-color: #424242 !important;
}

body.theme-pink-dark .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
    color: #fff !important;
}

body.theme-pink-dark .mat-table .mat-header-cell {
    color: rgba(255, 255, 255, 0.7) !important;
    background-color: #424242 !important;
}

body.theme-pink-dark .right-chat-panel .primary {
    background-color: #4d2184 !important;
}

body.theme-pink-dark .primary {
    background-color: #4d2184 !important;
}

body.theme-pink-dark app-inbox .center .header .search-wrapper .sidebar-toggle {
    color: #555;
}

body.theme-pink-dark .mat-datepicker-toggle-active {
    color: #fff !important;
}

body.theme-pink-dark .sidebar-precedents,
body.theme-pink-dark .sidebar-court {
    border: 1px solid #555 !important;
    background: #424242 !important;
}

body.theme-pink-dark .key_subClass {
    color: #fff !important;
}

body.theme-pink-dark .key_subClass:hover {
    background-color: #333;
}
body.theme-pink-dark .mat-raised-button.mat-accent {
    background-color: #4d2184 !important;
}

body.theme-pink-dark .e-float-input.e-control-wrapper:not(.e-input-focus):not(.e-disabled) input:not(:focus):not(:valid)~label.e-float-text:not(.e-label-top) {
    color: rgba(255,255,255,.7) !important;
}
body.theme-pink-dark .e-input-group .e-input-group-icon,
body.theme-pink-dark .e-input-group.e-control-wrapper .e-input-group-icon {
    border-color: rgba(255,255,255,.42) !important;
    color: rgba(255,255,255,.54) !important;
}
body.theme-pink-dark .e-float-input.e-control-wrapper:not(.e-error) input[readonly]~label.e-label-top.e-float-text,
body.theme-pink-dark .e-float-input.e-control-wrapper:not(.e-error) input~label.e-label-top.e-float-text,
body.theme-pink-dark .e-float-input.e-control-wrapper:not(.e-error).e-input-focus input~label.e-float-text {
    background-color: #424242 !important;
}
body.theme-pink-dark .e-float-input.e-control-wrapper:not(.e-error) input[readonly]~label.e-label-top.e-float-text,
body.theme-pink-dark .e-float-input.e-control-wrapper:not(.e-error) input~label.e-label-top.e-float-text,
body.theme-pink-dark .e-float-input.e-control-wrapper:not(.e-error).e-input-focus input~label.e-float-text {
    color: rgba(255,255,255,.7)!important;
    background-color: #303030 !important;
    // font-family: Muli,Helvetica Neue,Arial,sans-serif !important;
    font-family: 'Poppins', sans-serif !important;
}
body.theme-pink-dark .e-float-input.e-control-wrapper.e-input-group.e-input-focus:not(.e-float-icon-left) {
    border: 2px solid #fff !important;
}

body.theme-pink-dark .mat-dialog-container .e-float-input.e-control-wrapper:not(.e-error) input[readonly]~label.e-label-top.e-float-text,
body.theme-pink-dark .mat-dialog-container .e-float-input.e-control-wrapper:not(.e-error) input~label.e-label-top.e-float-text,
body.theme-pink-dark .mat-dialog-container .e-float-input.e-control-wrapper:not(.e-error).e-input-focus input~label.e-float-text {
    background-color: #424242 !important;
}
body.theme-pink-dark .mat-dialog-container .e-float-input.e-control-wrapper:not(.e-error) input[readonly]~label.e-label-top.e-float-text,
body.theme-pink-dark .mat-dialog-container .e-float-input.e-control-wrapper:not(.e-error) input~label.e-label-top.e-float-text,
body.theme-pink-dark .mat-dialog-container .e-float-input.e-control-wrapper:not(.e-error).e-input-focus input~label.e-float-text {
    color: rgba(255,255,255,.7)!important;
    background-color: #424242 !important;
}
body.theme-pink-dark .e-float-input input[readonly], body.theme-pink-dark .e-float-input.e-control-wrapper input[readonly] {
    color: #fff !important;
}
body.theme-pink-dark app-silqpay a {
    color: #3d0584 !important;
}
body.theme-pink-dark .detail-mail-doc-name {
    color: rgba(255, 255, 255, 0.8);
}
body.theme-pink-dark .attachment-list a {
    color: #3d0584 !important;
}

body.theme-pink-dark .e-schedule .e-vertical-view .e-header-cells.e-current-day {
    color: #3d0584 !important;
}
body.theme-pink-dark .e-schedule .e-vertical-view .e-clone-time-indicator,
body.theme-pink-dark .e-schedule .e-vertical-view .e-current-time {
    color: #3d0584 !important;
}
body.theme-pink-dark .e-schedule .e-vertical-view .e-current-timeline {
    border-top: 1px solid #3d0584 !important;
}
body.theme-pink-dark .e-schedule .e-vertical-view .e-day-wrapper .e-appointment {
    background: #3d0584 !important;
    border: 1px solid #3d0584 !important;
}
body.theme-pink-dark .e-schedule .e-vertical-view .e-previous-timeline {
    border-top: 1px dotted #3d0584 !important;
}
body.theme-pink-dark .e-schedule .e-month-view .e-appointment {
    background: #3d0584 !important;
}
body.theme-pink-dark .e-schedule .e-month-view .e-current-date .e-date-header,
body.theme-pink-dark .e-schedule .e-month-agenda-view .e-current-date .e-date-header {
    background-color: #3d0584 !important;
}
body.theme-pink-dark .e-schedule .e-month-view .e-date-header-wrap table td.e-current-day,
body.theme-pink-dark .e-schedule .e-month-agenda-view .e-date-header-wrap table td.e-current-day {
    color: #3d0584 !important;
}
body.theme-pink-dark .e-schedule .e-month-view .e-current-date {
    background-color: #b7a4d0 !important;
}
body.theme-pink-dark .e-schedule .e-schedule-toolbar .e-active-view .e-tbar-btn-text,
body.theme-pink-dark .e-schedule .e-schedule-toolbar .e-active-view .e-icons {
    color: #3d0584 !important;
}
body.theme-pink-dark .upload_doc_header {
    background-color: #3d0584;
}

// Diary calendar dark mode start here
body.theme-pink-dark .schedule-cal-custom.e-schedule .e-schedule-toolbar .e-toolbar-items,
body.theme-blue-gray-dark .schedule-cal-custom.e-schedule .e-schedule-toolbar .e-toolbar-items {
    background: #303030 !important;
}
body.theme-pink-dark .schedule-cal-custom.e-schedule .e-month-view .e-work-cells,
body.theme-pink-dark .schedule-cal-custom.e-schedule .e-month-agenda-view .e-work-cells,
body.theme-blue-gray-dark .schedule-cal-custom.e-schedule .e-month-view .e-work-cells,
body.theme-blue-gray-dark .schedule-cal-custom.e-schedule .e-month-agenda-view .e-work-cells  {
    background-color: #424242;
    color: #fff;
}
body.theme-pink-dark .schedule-cal-custom.e-schedule .e-month-view .e-work-cells:hover,
body.theme-pink-dark .schedule-cal-custom.e-schedule .e-month-agenda-view .e-work-cells:hover,
body.theme-blue-gray-dark .schedule-cal-custom.e-schedule .e-month-view .e-work-cells:hover,
body.theme-blue-gray-dark .schedule-cal-custom.e-schedule .e-month-agenda-view .e-work-cells:hover  {
    background: #303030;
    color: #fff;
}
body.theme-pink-dark .schedule-cal-custom.e-schedule .e-schedule-toolbar .e-tbar-btn,
body.theme-blue-gray-dark .schedule-cal-custom.e-schedule .e-schedule-toolbar .e-tbar-btn{
    background: #525252 !important;
    border-radius: 3px !important;
}
body.theme-pink-dark .schedule-cal-custom.e-schedule .e-schedule-toolbar .e-tbar-btn span.e-tbar-btn-text,
body.theme-blue-gray-dark .schedule-cal-custom.e-schedule .e-schedule-toolbar .e-tbar-btn span.e-tbar-btn-text {
    color: #fff;
}
body.theme-pink-dark .schedule-cal-custom.e-schedule .e-month-view .e-date-header-wrap table td,
body.theme-pink-dark .schedule-cal-custom.e-schedule .e-month-agenda-view .e-date-header-wrap table td,
body.theme-blue-gray-dark .schedule-cal-custom.e-schedule .e-month-view .e-date-header-wrap table td,
body.theme-blue-gray-dark .schedule-cal-custom.e-schedule .e-month-agenda-view .e-date-header-wrap table td{
    background-color: #303030;
    color: #b3b3b3 !important;
}
// body.theme-pink-dark .schedule-cal-custom.e-schedule .e-month-view .e-date-header-wrap table td.e-current-day,
// body.theme-pink-dark .schedule-cal-custom.e-schedule .e-month-agenda-view .e-date-header-wrap table td.e-current-day {
//     color: #aa67ff !important;
// }
body.theme-pink-dark .schedule-cal-custom.e-schedule,
body.theme-blue-gray-dark .schedule-cal-custom.e-schedule {
    border: 1px solid #595959;
}
// body.theme-pink-dark .schedule-cal-custom.e-schedule .e-schedule-toolbar .e-active-view .e-tbar-btn-text,
// body.theme-pink-dark .schedule-cal-custom.e-schedule .e-schedule-toolbar .e-active-view .e-icons {
//     color: #b077f9 !important;
// }
body.theme-pink-dark .schedule-cal-custom .e-quick-popup-wrapper .e-event-popup .e-popup-content,
body.theme-blue-gray-dark .schedule-cal-custom .e-quick-popup-wrapper .e-event-popup .e-popup-content {
    background-color: #595959;
}
body.theme-pink-dark .schedule-cal-custom .e-quick-popup-wrapper .e-event-popup .e-popup-content .e-icons,
body.theme-blue-gray-dark .schedule-cal-custom .e-quick-popup-wrapper .e-event-popup .e-popup-content .e-icons {
    color: #fff;
}
body.theme-pink-dark .schedule-cal-custom .e-quick-popup-wrapper .e-event-popup .e-popup-content .e-text-ellipsis,
body.theme-blue-gray-dark .schedule-cal-custom .e-quick-popup-wrapper .e-event-popup .e-popup-content .e-text-ellipsis {
    color: #fff;
}
body.theme-pink-dark .schedule-cal-custom .e-more-popup-wrapper.e-lib.e-popup.e-control.e-popup-open,
body.theme-blue-gray-dark .schedule-cal-custom .e-more-popup-wrapper.e-lib.e-popup.e-control.e-popup-open {
    background-color: #595959;
    border-color: #424242;
}
body.theme-pink-dark .schedule-cal-custom .e-more-event-date-header .e-header-day,
body.theme-blue-gray-dark .schedule-cal-custom .e-more-event-date-header .e-header-day {
    color: #fff;
}
body.theme-pink-dark .schedule-cal-custom .e-header-date.e-navigate,
body.theme-blue-gray-dark .schedule-cal-custom .e-header-date.e-navigate {
    color: #fff;
}
body.theme-pink-dark .schedule-cal-custom .e-icons.e-close-icon,
body.theme-blue-gray-dark .schedule-cal-custom .e-icons.e-close-icon {
    color: #fff;
}
body.theme-pink-dark .schedule-cal-custom.e-schedule .e-month-view .e-more-indicator,
body.theme-blue-gray-dark .schedule-cal-custom.e-schedule .e-month-view .e-more-indicator {
    color: #fff;
}
body.theme-pink-dark .schedule-cal-custom.e-schedule .e-month-view .e-current-date {
    background-color: #8a59ca !important;
}
body.theme-pink-dark .schedule-cal-custom.e-schedule .e-calendar,
body.theme-pink-dark .schedule-cal-custom.e-schedule .e-bigger.e-small .e-calendar,
body.theme-blue-gray-dark .schedule-cal-custom.e-schedule .e-calendar,
body.theme-blue-gray-dark .schedule-cal-custom.e-schedule .e-bigger.e-small .e-calendar  {
    background-color: #595959;
}
body.theme-pink-dark .schedule-cal-custom .e-calendar .e-content.e-year td > span.e-day,
body.theme-pink-dark .schedule-cal-custom .e-calendar .e-content.e-decade td > span.e-day,
body.theme-pink-dark .schedule-cal-custom .e-bigger.e-small .e-calendar .e-content.e-year td > span.e-day,
body.theme-pink-dark .schedule-cal-custom .e-bigger.e-small .e-calendar .e-content.e-decade td > span.e-day,
body.theme-blue-gray-dark .schedule-cal-custom .e-calendar .e-content.e-year td > span.e-day,
body.theme-blue-gray-dark .schedule-cal-custom .e-calendar .e-content.e-decade td > span.e-day,
body.theme-blue-gray-dark .schedule-cal-custom .e-bigger.e-small .e-calendar .e-content.e-year td > span.e-day,
body.theme-blue-gray-dark .schedule-cal-custom .e-bigger.e-small .e-calendar .e-content.e-decade td > span.e-day  {
    background: #595959;
    color: #fff;
}
body.theme-pink-dark .schedule-cal-custom .e-calendar .e-header .e-title,
body.theme-pink-dark .schedule-cal-custom .e-bigger.e-small .e-calendar .e-header .e-title,
body.theme-blue-gray-dark .schedule-cal-custom .e-calendar .e-header .e-title,
body.theme-blue-gray-dark .schedule-cal-custom .e-bigger.e-small .e-calendar .e-header .e-title  {
    color: #fff;
}
body.theme-pink-dark .schedule-cal-custom .e-calendar .e-date-icon-prev,
body.theme-pink-dark .schedule-cal-custom .e-calendar .e-date-icon-next,
body.theme-pink-dark .schedule-cal-custom .e-bigger.e-small .e-calendar .e-date-icon-prev,
body.theme-pink-dark .schedule-cal-custom .e-bigger.e-small .e-calendar .e-date-icon-next,
body.theme-blue-gray-dark .schedule-cal-custom .e-calendar .e-date-icon-prev,
body.theme-blue-gray-dark .schedule-cal-custom .e-calendar .e-date-icon-next,
body.theme-blue-gray-dark .schedule-cal-custom .e-bigger.e-small .e-calendar .e-date-icon-prev,
body.theme-blue-gray-dark .schedule-cal-custom .e-bigger.e-small .e-calendar .e-date-icon-next{
    color: #fff;
}
body.theme-pink-dark .schedule-cal-custom .e-toolbar .e-icons,
body.theme-blue-gray-dark .schedule-cal-custom .e-toolbar .e-icons {
    color: #f3f3f3;
}
body.theme-pink-dark .schedule-cal-custom.e-schedule .e-vertical-view .e-date-header-wrap table tbody td,
body.theme-blue-gray-dark .schedule-cal-custom.e-schedule .e-vertical-view .e-date-header-wrap table tbody td {
    background-color: #424242;
    border-color: #595959;
}
body.theme-pink-dark .schedule-cal-custom.e-schedule .e-vertical-view .e-date-header-wrap table tbody td,
body.theme-blue-gray-dark .schedule-cal-custom.e-schedule .e-vertical-view .e-date-header-wrap table tbody td {
    background-color: #424242;
    color: #fff;
}
body.theme-pink-dark .schedule-cal-custom.e-schedule .e-vertical-view .e-left-indent-wrap table tbody td,
body.theme-blue-gray-dark .schedule-cal-custom.e-schedule .e-vertical-view .e-left-indent-wrap table tbody td {
    background-color: #424242;
}
body.theme-pink-dark .schedule-cal-custom .e-day-view .e-schedule-table.e-content-table tr:nth-child(even) td,
body.theme-blue-gray-dark .schedule-cal-custom .e-day-view .e-schedule-table.e-content-table tr:nth-child(even) td {
    background-color: #303030;
}
body.theme-pink-dark .schedule-cal-custom .e-day-view .e-schedule-table.e-content-table tr:nth-child(odd) td,
body.theme-blue-gray-dark .schedule-cal-custom .e-day-view .e-schedule-table.e-content-table tr:nth-child(odd) td {
    background-color: #424242 !important;
}
body.theme-pink-dark .schedule-cal-custom .e-day-view .e-time-cells-wrap .e-schedule-table tr:nth-child(even) td,
body.theme-blue-gray-dark .schedule-cal-custom .e-day-view .e-time-cells-wrap .e-schedule-table tr:nth-child(even) td {
    background-color: #303030;
    color: #fff;
}
body.theme-pink-dark .schedule-cal-custom .e-day-view .e-time-cells-wrap .e-schedule-table tr:nth-child(odd) td,
body.theme-blue-gray-dark .schedule-cal-custom .e-day-view .e-time-cells-wrap .e-schedule-table tr:nth-child(odd) td{
    background-color: #424242 !important;
    color: #b1b1b1;
}
body.theme-pink-dark .schedule-cal-custom .e-calendar th,
body.theme-pink-dark .schedule-cal-custom .e-bigger.e-small .e-calendar th,
body.theme-blue-gray-dark .schedule-cal-custom .e-calendar th,
body.theme-blue-gray-dark .schedule-cal-custom .e-bigger.e-small .e-calendar th{
    color: #fff;
}
body.theme-pink-dark .schedule-cal-custom .e-calendar .e-content span,
body.theme-pink-dark .schedule-cal-custom .e-bigger.e-small .e-calendar .e-content span,
body.theme-blue-gray-dark .schedule-cal-custom .e-calendar .e-content span,
body.theme-blue-gray-dark .schedule-cal-custom .e-bigger.e-small .e-calendar .e-content span {
    color: #fff;
}
html body.theme-pink-dark .schedule-cal-custom.e-schedule .e-vertical-view .e-work-hours,
html body.theme-blue-gray-dark .schedule-cal-custom.e-schedule .e-vertical-view .e-work-hours {
    background-color: #424242;
}
body.theme-pink-dark .schedule-cal-custom.e-schedule .e-vertical-view .e-work-cells,
body.theme-blue-gray-dark .schedule-cal-custom.e-schedule .e-vertical-view .e-work-cells {
    background-color: #303030;
}
body.theme-pink-dark .schedule-cal-custom.e-schedule .e-vertical-view .e-work-cells:hover,
body.theme-blue-gray-dark .schedule-cal-custom.e-schedule .e-vertical-view .e-work-cells:hover {
    background: #2d2d2d;
}
body.theme-pink-dark .schedule-cal-custom.e-schedule .e-vertical-view .e-time-cells-wrap table td,
body.theme-blue-gray-dark .schedule-cal-custom.e-schedule .e-vertical-view .e-time-cells-wrap table td {
    background-color: #424242;
    color: #fff !important;
}
body.theme-pink-dark .schedule-cal-custom.e-schedule,
body.theme-blue-gray-dark .schedule-cal-custom.e-schedule {
    background-color: #424242;
}
body.theme-pink-dark .schedule-cal-custom.e-schedule .e-agenda-view,
body.theme-blue-gray-dark .schedule-cal-custom.e-schedule .e-agenda-view {
    background-color: #424242;
}
body.theme-pink-dark .schedule-cal-custom.e-schedule .e-agenda-view .e-empty-event,
body.theme-blue-gray-dark .schedule-cal-custom.e-schedule .e-agenda-view .e-empty-event {
    color: #fff;
}
body.theme-pink-dark .schedule-cal-custom .e-calendar .e-content td:hover span.e-day,
body.theme-pink-dark .schedule-cal-custom .e-calendar .e-content td:focus span.e-day,
body.theme-pink-dark .schedule-cal-custom .e-bigger.e-small .e-calendar .e-content td:hover span.e-day,
body.theme-pink-dark .schedule-cal-custom .e-bigger.e-small .e-calendar .e-content td:focus span.e-day,
body.theme-blue-gray-dark .schedule-cal-custom .e-calendar .e-content td:hover span.e-day,
body.theme-blue-gray-dark .schedule-cal-custom .e-calendar .e-content td:focus span.e-day,
body.theme-blue-gray-dark .schedule-cal-custom .e-bigger.e-small .e-calendar .e-content td:hover span.e-day,
body.theme-blue-gray-dark .schedule-cal-custom .e-bigger.e-small .e-calendar .e-content td:focus span.e-day  {
    background-color: #303030;
}
// Diary calendar dark mode end here



body.theme-default .solicitor-container .filter-button .mat-button-wrapper,
body.theme-pink-dark .solicitor-container .filter-button .mat-button-wrapper,
body.theme-default .filter-button .mat-button-wrapper,
body.theme-pink-dark .filter-button .mat-button-wrapper
 {
    color: #ad6cff !important;
    //  #b187e2
}
body.theme-pink-dark .filter-icon,
body.theme-default .filter-icon {
filter: invert(57%) sepia(60%) saturate(690%) hue-rotate(219deg) brightness(92%) contrast(47%) !important;
}
body.theme-pink-dark .back-tick img,
body.theme-default  .back-tick img {
    filter:invert(57%) sepia(60%) saturate(690%) hue-rotate(219deg) brightness(92%) contrast(47%) !important;
}

body.theme-pink-dark .addbutton,
body.theme-blue-gray-dark .addbutton {
    background: #424242;
}
