// GREEN CLOR :::::::::(Solicitor)
// ---------------> dark ::::::::::  theme-blue-gray-dark
// ---------------> light :::::::::: theme-yellow-light

// PUROLE CLOR (Barrister)
//--------------->   dark  :::::::::: theme-default
// ---------------> light :::::::::: theme-pink-dark

:root{
//START  Barrister  (Purple threme)
// Barrister LIGHT
  .theme-default{
    --cardColor: #fff;
    --tableColor: #988c8c;
    --filter: none;
    --btnColor: #4d2184;
    --btnColorBG: #fff;
    --boxColor: #e1e8f0;
    --textColor: #222;
     --textActiveColor: #fff;

    --text-secondary-rgb: #202020;
    --greybg: rgba(248, 250, 252, 1);
    --timelineTextColor: #000;

    --selectionText: #000;
  }
// Barrister DARK
    .theme-pink-dark{
        --btnColor: #3b0a79;
        --btnColorBG: #fff;
        --boxColor: #424242;
        --textColor: #fff;
        --textActiveColor: #fff;
        --timelineTextColor: #fff;

        --selectionText: #fff;
    }

//START  soliciter (green threme)
// soliciter DARK
    .theme-blue-gray-dark{
        --cardColor: #424242;
        --tableColor: #fff;
        --filter: invert(100%) sepia(0%) saturate(1%) hue-rotate(317deg) brightness(108%) contrast(101%);
        --btnColor: #43a047;
        --btnColorBG: transparent;
        // --boxColor: #424242;
        --boxColor: #e1e8f0;
        --textColor: #fff;
        --textActiveColor: #fff;
        --timelineTextColor: #000;
        --selectionText: #fff;
    }
// soliciter LiGHT
    .theme-yellow-light{
        --btnColor: #43a047;
        --btnColorBG: transparent;
        --boxColor: #e1e8f0;
        --textColor: #222;
        --textActiveColor: #fff;
        --timelineTextColor: #000;
        --selectionText: #000;
    }
//END  soliciter (grren threme)

}
