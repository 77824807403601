@mixin sign-up-2-theme($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);
    sign-up-2 {
        #login {
            #login-intro {
                color: white;
            }
            #login-form-wrapper {
                @if ($is-dark) {
                    background: mat-color($fuse-navy, 600);
                }
                @else {
                    background: map-get($background, card);
                }
                #login-form {
                    .separator {
                        color: map-get($foreground, divider);
                        .text {
                            &:before,
                            &:after {
                                border-top-color: map-get($foreground, divider);
                            }
                        }
                    }
                }
            }
        }
    }
}