// -----------------------------------------------------------------------------------------------------
// @ Custom color maps for Fuse
// -----------------------------------------------------------------------------------------------------
$fuse-white: (
    500: white,
    contrast: (500: $dark-primary-text)
);
$fuse-black: (
    500: black,
    contrast: (500: $light-primary-text)
);
$fuse-navy: (
    50: #ececee,
    100: #c5c6cb,
    200: #9ea1a9,
    300: #7d818c,
    400: #5c616f,
    500: #3c4252,
    600: #353a48,
    700: #2d323e,
    800: #262933,
    900: #1e2129,
    A100: #c5c6cb,
    A200: #9ea1a9,
    A400: #5c616f,
    A700: #2d323e,
    contrast: (50: $dark-primary-text, 100: $dark-primary-text, 200: $dark-primary-text, 300: $light-primary-text, 400: $light-primary-text, 500: $light-primary-text, 600: $light-primary-text, 700: $light-primary-text, 800: $light-primary-text, 900: $light-primary-text, A100: $dark-primary-text, A200: $light-primary-text, A400: $light-primary-text, A700: $light-primary-text)
);
// Generate Fuse color classes for custom palettes
$custom_palettes: (
    fuse-white: $fuse-white,
    fuse-black: $fuse-black,
    fuse-navy: $fuse-navy
);
@include fuse-color-classes($custom_palettes);
// -----------------------------------------------------------------------------------------------------
// @ Typography
// -----------------------------------------------------------------------------------------------------
// Angular Material typography
// "Muli, Helvetica Neue, Arial, sans-serif"
$typography: mat-typography-config($font-family: 'Poppins, sans-serif', $title: mat-typography-level(20px, 32px, 600), $body-2: mat-typography-level(14px, 24px, 600), $button: mat-typography-level(14px, 14px, 600), $input: mat-typography-level(16px, 1.125, 400));
// line-height must be unitless !!!
// Setup the typography
@include angular-material-typography($typography);
// -----------------------------------------------------------------------------------------------------
// @ Component theming
// -----------------------------------------------------------------------------------------------------
// Import app and page component themes here to enable theming for them
@import "src/app/main/pages/Task/task-reminder-details/chat-panel.theme";
@import "src/app/layout/components/toolbar/toolbar.theme";
@import "src/app/main/pages/collegeof-law/login.theme.scss";
@import "src/app/main/authentication/login/login-2.theme.scss";
@import "src/app/main/authentication/forgot-password/forgot-password-2.theme.scss";
@import "src/app/main/authentication/sign-up/sign-up-2.theme.scss";
@import "src/app/main/pages/diary/calendar.theme.scss";
@import "src/app/main/errors/not-found/error-404.theme.scss";
@import "src/app/main/errors/internal-error/error-500.theme.scss";
@import "src/app/main/pages/inbox/mail.theme.scss";
/// ejs tree   Css
@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-grids/styles/material.css';
@import '../node_modules/@syncfusion/ej2-treegrid/styles/material.css';
/// ejs dropdown tree   Css
@import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-dropdowns/styles/material.css';
//ej2 schedule Calander css
@import '../node_modules/@syncfusion/ej2-angular-schedule/styles/material.css';

// @import "src/app/main/pages/contact/contacts.theme.scss";
// Define a mixin for easier access
@mixin components-theme($theme) {
    // Layout components
    @include chat-panel-theme($theme);
    @include toolbar-theme($theme);
    @include login-theme($theme);
    @include login-2-theme($theme);
    @include forgot-password-2-theme($theme);
    @include sign-up-2-theme($theme);
    @include calendar-theme($theme);
    @include error-404-theme($theme);
    @include error-500-theme($theme);
    @include mail-theme($theme);
    // @include contacts-theme($theme);
}

// -----------------------------------------------------------------------------------------------------
// @ Define the default theme
// -----------------------------------------------------------------------------------------------------
// Define the primary, accent and warn palettes
$default-primary-palette: mat-palette($fuse-navy);
$default-accent-palette: mat-palette($mat-light-blue, 600, 400, 700);
$default-warn-palette: mat-palette($mat-red);
// Create the Material theme object
$theme: mat-light-theme($default-primary-palette, $default-accent-palette, $default-warn-palette);

// Add ".theme-default" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-default {
    // Create an Angular Material theme from the $theme map
    @include angular-material-theme($theme);
    // Apply the theme to the Fuse Core
    @include fuse-core-theme($theme);
    // Apply the theme to the user components
    @include components-theme($theme);
    // Generate Fuse color classes for primary, accent and warn colors
    $palettes: (
        primary: $default-primary-palette,
        accent: $default-accent-palette,
        warn: $default-warn-palette
    );
@include fuse-color-classes($palettes);
}

// -----------------------------------------------------------------------------------------------------
// @ Define a yellow light theme
// -----------------------------------------------------------------------------------------------------
// Define the primary, accent and warn palettes
$yellow-light-theme-primary-palette: mat-palette($fuse-navy, 600, 400, 700);
$yellow-light-theme-accent-palette: mat-palette($mat-green, 600, 400, 700);
$yellow-light-theme-warn-palette: mat-palette($mat-red);
// Create the Material theme object
$yellow-light-theme: mat-light-theme($yellow-light-theme-primary-palette, $yellow-light-theme-accent-palette, $yellow-light-theme-warn-palette);

// Add ".theme-yellow-light" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-yellow-light {
    // Generate the Angular Material theme
    @include angular-material-theme($yellow-light-theme);
    // Apply the theme to the Fuse Core
    @include fuse-core-theme($yellow-light-theme);
    // Apply the theme to the user components
    @include components-theme($yellow-light-theme);
    // Generate Fuse color classes for primary, accent and warn colors
    $palettes: (
        primary: $yellow-light-theme-primary-palette,
        accent: $yellow-light-theme-accent-palette,
        warn: $yellow-light-theme-warn-palette
    );
@include fuse-color-classes($palettes);
}

// -----------------------------------------------------------------------------------------------------
// @ Define a blue-gray dark theme
// -----------------------------------------------------------------------------------------------------
// Define the primary, accent and warn palettes
$blue-gray-dark-theme-primary-palette: mat-palette($mat-blue);
$blue-gray-dark-theme-accent-palette: mat-palette($mat-blue-gray);
$blue-gray-dark-theme-warn-palette: mat-palette($mat-red);
// Create the Material theme object
$blue-gray-dark-theme: mat-dark-theme($blue-gray-dark-theme-primary-palette, $blue-gray-dark-theme-accent-palette, $blue-gray-dark-theme-warn-palette);

// Add ".theme-blue-gray-dark" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-blue-gray-dark {
    // Generate the Angular Material theme
    @include angular-material-theme($blue-gray-dark-theme);
    // Apply the theme to the Fuse Core
    @include fuse-core-theme($blue-gray-dark-theme);
    // Apply the theme to the user components
    @include components-theme($blue-gray-dark-theme);
    // Generate Fuse color classes for primary, accent and warn colors
    $palettes: (
        primary: $blue-gray-dark-theme-primary-palette,
        accent: $blue-gray-dark-theme-accent-palette,
        warn: $blue-gray-dark-theme-warn-palette
    );
@include fuse-color-classes($palettes);
}

// -----------------------------------------------------------------------------------------------------
// @ Define a pink dark theme
// -----------------------------------------------------------------------------------------------------
// Define the primary, accent and warn palettes
$pink-dark-theme-primary-palette: mat-palette($mat-pink);
$pink-dark-theme-accent-palette: mat-palette($mat-pink);
$pink-dark-theme-warn-palette: mat-palette($mat-red);
// Create the Material theme object
$pink-dark-theme: mat-dark-theme($pink-dark-theme-primary-palette, $pink-dark-theme-accent-palette, $pink-dark-theme-warn-palette);

// Add ".theme-pink-dark" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-pink-dark {
    // Generate the Angular Material theme
    @include angular-material-theme($pink-dark-theme);
    // Apply the theme to the Fuse Core
    @include fuse-core-theme($pink-dark-theme);
    // Apply the theme to the user components
    @include components-theme($pink-dark-theme);
    // Generate Fuse color classes for primary, accent and warn colors
    $palettes: (
        primary: $pink-dark-theme-primary-palette,
        accent: $pink-dark-theme-accent-palette,
        warn: $pink-dark-theme-warn-palette
    );
@include fuse-color-classes($palettes);
}


/***** Moon css ******/

.sticky_search_div .mat-form-field-wrapper {
    padding-bottom: 0 !important;
}

.sticky_search_div .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 0.5em 0 0.5em 0;
}

.sticky_search_div .mat-form-field-appearance-outline .mat-form-field-label {
    top: 1.34375em;
    margin-top: -0.25em;
}

.top_right_action {
    position: absolute;
    right: 30px;
    top: 30px;
}

.top_right_action.matter_right_action {
    position: absolute;
    right: 10px;
    top: 0;
    z-index: 999;
    background-color: #fff;
    height: 55px;
    width: 55px;
    line-height: 55px;
}

.top_right_action.matter_right_action button {
    height: 55px;
    width: 55px;
    line-height: 55px;
}

.top_right_action_billing {
    position: absolute;
    right: 35px;
    top: 104px;
    background-color: #fff;
    z-index: 101;
}

.contact_right_action {
    position: absolute;
    right: 0;
    top: 0;
    background-color: #fff;
    z-index: 101;
    height: 54px;
    border-top-right-radius: 5px;
}

.contact_right_action .mat-icon-button {
    width: 55px;
    height: 55px;
    line-height: 55px;
}

.top_right_action_billing_one {
    position: absolute;
    right: 0;
    top: 0;
    background-color: #fff;
    z-index: 101;
    width: 55px;
    height: 55px;
    line-height: 55px;
}

.top_right_action_billing_one button {
    width: 55px;
    height: 55px;
    line-height: 55px;
}

.top_right_action_chronology {
    position: absolute;
    right: 30px;
    top: 30px;
}

.top_right_action_billing_position {
    position: absolute;
    right: 10px;
    top: 0;
    background-color: #fff;
    z-index: 101;
    height: 55px;
    border-top-right-radius: 5px;
}

.top_right_action_alloawnces {
    right: 24px !important;
    top: 46px !important;
}

.top_right_action_billing_position .mat-icon-button {
    width: 55px;
    height: 55px;
    line-height: 55px;
}

// .top_right_action button {
//     position: fixed;
//     right: 10px;
//     top: 8px;
//     z-index: 999;
// }
.list_sidebar_padding h3.one {
    margin: 0 !important;
}

.sticky_search_div .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    -webkit-transform: translateY(-1.19375em) scale(0.75) !important;
    transform: translateY(-1.09375em) scale(0.75) !important;
}

app-matters-list {
    position: relative;
}

// .top_right_action {
//     position: sticky;
//     left: 100%;
//     top: 0;
//     z-index: 1000;
// }
.mat-select-panel {
    top: -8px;
    position: relative;
}

.mat-option {
    cursor: pointer;
    font-size: 11px !important;
}


/****** popup cmn css *****/

body.theme-default .mat-dialog-container {
    padding: 0;
}

body.theme-yellow-light .mat-dialog-container {
    padding: 0;
}

.popup_main_div .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 0.5em 0 0.5em 0;
}

.popup_main_div .mat-form-field-appearance-outline .mat-form-field-label {
    top: 1.34375em;
}

.popup_main_div .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    -webkit-transform: translateY(-1.09375em) scale(0.75);
    transform: translateY(-1.09375em) scale(0.75);
}

.mat-menu-panel {
    min-width: 180px !important;
}

.mat-menu-panel .mat-menu-item {
    height: 40px;
    line-height: 40px;
}


/****** popup cmn css user 2 *****/

body.theme-yellow-light .mat-dialog-container {
    padding: 0;
}

// .popup_main_div .mat-form-field-appearance-outline .mat-form-field-infix {
//     padding: 0.5em 0 0.5em 0;
// }
// .popup_main_div .mat-form-field-appearance-outline .mat-form-field-label {
//     top: 1.34375em;
// }
.popup_main_div .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    -webkit-transform: translateY(-1.09375em) scale(0.75);
    transform: translateY(-1.09375em) scale(0.75);
}

.mat-menu-panel {
    min-width: 180px !important;
}

.mat-menu-panel .mat-menu-item {
    height: 40px;
    line-height: 40px;
}

// .reports_tab_main {
//     width: 160%;
//     overflow: auto;
// }
// toolbar .reports_tab_main .group-section-tab {
//     display: inline-block;
//     float: left;
// }
// .reports_tab_main .group-section-tab .ribbon-section {
//     float: left;
// }
// .reports_tab_main {
//   width: 75%;
// }
.reports_tab_main .mat-card {
    width: 100%;
}

toolbar .ribbon-container.reports_tab_main .mat-card-content {
    display: flex;
    // overflow: auto;
    width: 100%;
}

//for camel case
.header_title {
    text-transform: capitalize;
}

.w-timepicker-dialog {
    padding: 0 !important;
    margin: 0 !important;
    width: calc(100% + 0px) !important;
}

.mat-form-field .mat-icon {
    cursor: pointer !important;
}

.time_entry_picker .mat-form-field-appearance-outline .mat-form-field-suffix {
    top: 0;
}

body.theme-default .list_sidebar_right_child .mat-card {
    box-shadow: none;
}

body.theme-default .mat-spinner circle {
    stroke: #572b82 !important;
}

body.theme-yellow-light .mat-progress-spinner circle,
body.theme-yellow-light .mat-spinner circle {
    stroke: #43a047 !important;
}

.example-loading-shade {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0px;
    right: 0;
    background: rgba(0, 0, 0, 0.15);
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
}

app-time-billing {
    overflow-y: auto;
}

.cmn_active_tab {
    background-color: rgba(0, 0, 0, 0.2) !important;
}

.matter-details-side-nav,
.my-profile-side-nav {
    .cmn_active_tab {
        // background-color: rgba(0, 0, 0, 0.2) !important;
        background-color: var(--btnColor) !important;
        color: var(--textActiveColor) !important;
    }
}

.font_class {
    text-transform: capitalize;
}

th.mat-header-cell {
    text-transform: capitalize;
}

tr.mat-ripple.mat-row.row-text-colore td {
    color: #000000 !important;
}

tr.mat-row.has-orange-colore td {
    color: #FFA500;
}

tr.mat-row.has-orange-colore {
    color: #FFA500;
}

.mat-row.has-red-colore .mat-cell {
    color: #ff0000;
}

.mat-row.has-red-colore {
    color: #ff0000;
}

.mat-row.has-orange-colore .mat-cell {
    color: #FFA500;
}

.mat-row.has-orange-colore {
    color: #FFA500;
}

.mat-row.has-light-blue-colore .mat-cell {
    color: #2b9ad1;
}

.mat-row.has-light-blue-colore {
    color: #2b9ad1;
}

tr.mat-row.has-light-blue-colore td {
    color: #1b3746;
}

tr.mat-row.has-light-blue-colore {
    color: #039be5;
}

//For Scoller add css
.example-containerdata {
    height: 400px;
    overflow: auto !important;
    position: relative;
}

.mat-dialog-container .example-containerdata {
    height: auto !important;
    // max-height: calc(100vh - 170px);
    max-height: calc(100vh - 260px);
}

.chat-panel .mat-form-field-wrapper {
    padding-bottom: 0;
}

.chat-panel .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 0.6em 0 0.6em 0;
}

.chat-panel .mat-form-field-appearance-outline .mat-form-field-label {
    top: 1.44375em;
}

.chat-panel .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    transform: translateY(-1.19375em) scale(0.75);
}

.grid-overlay {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
}

.mat-body p,
.mat-body-1 p,
.mat-typography p {
    margin-bottom: 0 !important;
}

// .grid-overlay-msg {
//     text-align: center;
//     margin: 10% auto 0;
//     background-color: darkgray;
//     width: 100%;
//     border-radius: 5px;
//     font-size: 15px;
//     line-height: 35px;
//     position: absolute;
// }
.grid-overlay-msg {
    text-align: center;
    margin: 8% auto 0;
    background-color: transparent;
    width: calc(100% - 364px);
    border-radius: 5px;
    font-size: 15px;
    line-height: 35px;
    position: absolute;
    left: 0px;
    right: 210px;
    z-index: 999999;
    color: #000;
    font-size: 20px;
    // left: 302px;
    // right: 32px;
}

table tbody tr,
mat-table mat-row {
    cursor: pointer;
}

body.theme-default .mat-table .mat-header-cell {
    color: rgba(0, 0, 0, 0.54);
    background-color: #ffffff;
}

.mat-row.row-text-colore td {
    color: #000000 !important;
}

.mat-row.row-text-colore mat-cell {
    color: #000000 !important;
}

mat-row.row-text-colore {
    color: #000000 !important;
}

.row-text-colore span {
    color: black;
}

.row-text-colore {
    color: #000000 !important;
}

// @media screen and (min-width: 1700px) {
//     .example-containerdata {
//         height: 500px;
//         overflow: auto;
//     }
// }
// @media screen and (max-width: 1400px) {
//     .example-containerdata {
//         height: 300px;
//     }
// }
body.theme-yellow-light .mat-table {
    background: white !important;
}

body.theme-default .mat-table {
    background: white !important;
}

.cdk-overlay-pane {
    max-width: 100% !important;
}

body.theme-default .mat-dialog-container {
    box-shadow: none !important;
    background-color: transparent !important;
}

body.theme-yellow-light .mat-dialog-container {
    box-shadow: none !important;
    background-color: transparent !important;
}

content.inner-scroll {
    overflow: auto;
}

// .sticky_search_div {
//     overflow: hidden !important;
// }
mat-spinner.mat-progress-spinner {
    zoom: 0 !important;
    height: 20px !important;
    width: 20px !important;
}

.mat-progress-spinner svg {
    height: 20px !important;
    width: 20px !important;
}

.add_edit_contact_main .mat-checkbox {
    position: relative;
    bottom: 10px;
}

.add_edit_contact_main button.mat-accent {
    margin-left: 20px;
}

.add_edit_contact_main .mat-checkbox.gst_charge {
    position: relative;
    bottom: 0;
}

.popup_main_div h2 {
    cursor: move;
}

.mat-dialog-container {
    overflow: visible !important;
}

.sideText {
    text-align: right;
}

.currency_sign {
    top: -7px;
    position: relative;
}

mat-dialog-actions {
    mat-spinner {
        zoom: 0.2;
        position: absolute;
        right: 0;
        left: 0;
        margin: auto;
        top: 0;
        bottom: 0;
    }
}

.has-error.mat-form-field-appearance-outline .mat-form-field-outline-start {
    border: 1px solid red;
    border-right: none;
}

.has-error.mat-form-field-appearance-outline .mat-form-field-outline-gap {
    border-radius: 0.000001px;
    border: 1px solid red;
    border-left-style: none;
    border-right-style: none;
}

.has-error.mat-form-field-appearance-outline .mat-form-field-outline-end {
    border: 1px solid red;
    border-left: none;
}

body.theme-default .has-error .mat-form-field-label {
    color: red;
}

.has-warning.mat-form-field-appearance-outline .mat-form-field-outline-start {
    border: 1px solid orange;
    border-right: none;
}

.has-warning.mat-form-field-appearance-outline .mat-form-field-outline-gap {
    border-radius: 0.000001px;
    border: 1px solid orange;
    border-left-style: none;
    border-right-style: none;
}

.has-warning.mat-form-field-appearance-outline .mat-form-field-outline-end {
    border: 1px solid orange;
    border-left: none;
}

body.theme-default .has-warning .mat-form-field-label {
    color: orange;
}

body .row-text-colore .mat-cell,
body .row-text-colore mat-cell {
    color: #000000 !important;
}

.value_width .mat-form-field-wrapper {
    padding-bottom: 0;
}

.mat_field_btm_padding .mat-form-field-wrapper {
    padding-bottom: 0;
}

body .mat-input-element {
    position: relative;
    bottom: 3px !important;
}

// .mat-sort-header-arrow {
//     opacity: 1 !important;
//     transform: translateY(0) !important;
//     transition: none !important;
// }
td.text-L-align {
    text-align: left;
}

td.text-R-align {
    text-align: right;
}

td.text-C-align {
    text-align: center;
}

.text--align,
.text-R-align,
.text-L-align,
.text-C-align {
    // border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding: 10px 20px;
}

.cal-month-view {
    background-color: transparent !important;
}

// mark {
//     background: yellow;
//     outline: none !important;
//     font-weight: bold;
//     text-transform: uppercase;
// }
.timeentry_popup .one.mb-24 {
    margin-top: 0 !important;
}

.less_space_field .mat-form-field-wrapper {
    padding-bottom: 10px;
}

.invoice_add_dialoug_form .example-containerdata {
    height: auto !important;
}

.main_setting_div .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 0.5em 0 0.5em 0;
}

.main_setting_div .mat-form-field-appearance-outline .mat-form-field-label {
    top: 1.34375em;
    margin-top: -0.25em;
}

.main_setting_div .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    transform: translateY(-1.15375em) scale(0.75);
}

.main_setting_div .mat-form-field-wrapper {
    padding-bottom: 10px;
}

app-court-forms,
app-precedents {
    width: 100% !important;
}

body.theme-yellow-light .precedents_form_drop .mat-select-arrow,
body.theme-yellow-light .court_form_drop .mat-select-arrow {
    color: #fff;
}

body.theme-yellow-light .precedents_form_drop .mat-form-field.mat-focused .mat-form-field-label,
body.theme-yellow-light .court_form_drop .mat-form-field.mat-focused .mat-form-field-label {
    color: #fff;
}

.precedents_form_drop .mat-select-value-text,
.court_form_drop .mat-select-value-text {
    color: #fff;
}

.try_free_silq_main .mat-progress-bar {
    position: relative !important;
    width: 100%;
    float: left;
}

.try_free_silq_main .title-footer h2 {
    margin: 0 !important;
    float: left;
    width: 100%;
}

body.theme-yellow-light .popup_slider_wizard .mat-step-header .mat-step-icon {
    display: none;
}

.popup_slider_wizard .mat-horizontal-content-container {
    padding: 0;
}

.popup_slider_wizard .mat-horizontal-stepper-header-container {
    padding: 10px 40px;
}

.popup_slider_wizard .mat-horizontal-stepper-header {
    height: auto;
    padding: 0;
    margin: 0 5px;
}

.popup_slider_wizard .mat-horizontal-stepper-header-container {
    justify-content: center;
}

.popup_slider_wizard .mat-stepper-horizontal-line {
    display: none;
}

.popup_slider_wizard .mat-step-header .mat-step-label {
    min-width: auto;
}

.slider_bullet {
    height: 10px;
    width: 10px;
    background-color: #999;
    border-radius: 50%;
}

body.theme-yellow-light .mat-step-header .mat-step-label.mat-step-label-active .slider_bullet {
    background-color: #000;
}

.btm_email_margin .mat-form-field-wrapper {
    padding-bottom: 0;
}

.btm_email_margin .mat-stroked-button {
    border: 1px solid rebeccapurple !important;
    padding: 0 15px !important;
    line-height: 40px;
}

.settlement .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 0.6em 0 0.6em 0;
}

.settlement .mat-form-field-appearance-outline .mat-form-field-label {
    top: 1.44375em;
}

.settlement .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    transform: translateY(-1.19375em) scale(0.75);
}

.settlement .mat-form-field-wrapper {
    padding-bottom: 2px;
}

body.theme-yellow-light .accent.disable_bg_btn[disabled] {
    background-color: #43a047 !important;
    color: rgba(255, 255, 255, 0.5) !important;
}

#settlement {
    height: auto !important;
}

.table_overflow_auto {
    // max-height: calc(100vh - 500px);
    // height: auto;
    max-height: calc(100vh - 350px);
    height: auto;
    overflow-y: auto;
    min-height: 50px;
}

// body.theme-default .row-text-colore,
// body.theme-yellow-light .row-text-colore {
//     background-color: rgba(0, 0, 0, 0.04) !important;
// }
.relative_position_auto .grid-overlay-msg {
    position: relative;
    margin: 0 auto 0;
    right: 0;
    left: 0;
    padding: 20px 0 0;
}

.relative_position_auto .grid-overlay {
    position: relative;
}

.mat-header-row,
.mat-row {
    // width: auto !important;
    min-width: 100% !important;
}

#matterAddressForm {
    width: 100%;
}

.right_main_settlement.ps--active-y>.ps__rail-y {
    display: none;
}

.rate_tab_value .mat-radio-button {
    padding-right: 8px;
}

@media print {
    .sticky_search_div {
        display: none;
    }

    .p-24 {
        padding: 0 !important;
    }

    .page-layout.simple.left-sidebar.inner-sidebar>.content>.center>.content {
        border-radius: 0;
    }

    .mat-menu-panel {
        display: none !important;
    }
}

// Column Resizable
.resizable-table {
    mwlResizable {
        box-sizing: border-box;
    }

    mat-cell,
    mat-footer-cell,
    mat-header-cell {
        width: 200px;
        // word-break: break-all;
        word-break: break-word;
        flex: none;
        display: block;
    }

    mat-header-cell {
        border-left: 1px solid #eee;
    }

    .mat-header-cell {
        padding-left: 15px;
        padding-right: 15px;
        line-height: 55px;
        // line-height: normal;
        white-space: nowrap !important;
        word-break: normal !important;
        padding-top: 8px;
        padding-bottom: 8px;

        button {
            white-space: nowrap;
        }
    }

    .mat-sort-header-button {
        white-space: nowrap !important;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .mat-cell {
        padding-left: 12px;
        padding-right: 12px;
    }

    .mat-header-row,
    .mat-row {
        width: fit-content;
    }

    .mat-header-row {
        // padding-left: 8px;
    }

    .mat-cell,
    mat-cell {
        // display: inline-block;
        vertical-align: middle;
        line-height: normal;
        // white-space: nowrap;
        white-space: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        border-bottom: none !important;
        padding: 8px 20px;
    }

    .more_tbl_clmn .mat-header-cell,
    .more_tbl_clmn mat-header-cell {
        display: table-cell;
        white-space: nowrap !important;
        padding: 0 20px;
        // overflow: inherit;
        // position: sticky;
    }

    .more_tbl_clmn .mat-cell,
    .more_tbl_clmn mat-cell {
        display: table-cell;
        white-space: normal !important;
        padding: 0 20px;
        // overflow: inherit;
        // position: sticky;
    }
}

.resizable-table mat-cell {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    line-height: normal !important;
    word-break: break-word !important;
}

.more_tbl_clmn .mat-cell {
    word-break: break-word !important;
    white-space: normal !important;
}

.resizable-table .mat-header-cell,
.more_tbl_clmn .mat-header-cell {
    word-break: normal !important;
    white-space: nowrap !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

body form {
    width: 100%;
}

.custom-tooltip {
    font-size: 16px;
    white-space: pre-line;
    line-height: 30px;
    min-width: 350px;
    width: 100%;
}

.mat-spinner {
    margin: auto !important;
}

.align_center.mat-form-field-appearance-outline .mat-form-field-flex {
    display: flex;
    align-items: center;
}

navbar navbar-vertical-style-1 .navbar-scroll-container.sidebar_btm_div {
    padding-bottom: 20px;
}

.e-grid td.e-active {
    background: #d9d9d9 !important;
}

body.theme-yellow-light .mat-primary .mat-pseudo-checkbox-checked,
body.theme-yellow-light .mat-primary .mat-pseudo-checkbox-indeterminate {
    background: #43a047;
}

body.theme-default .mat-primary .mat-pseudo-checkbox-checked,
body.theme-default .mat-primary .mat-pseudo-checkbox-indeterminate {
    background: #4d2184;
}

body.theme-default .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: #4d2184;
}

body.theme-default .mat-badge-warn .mat-badge-content {
    background: #4d2184;
}

body.theme-default .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: #4d2184 !important;
}

.cal-month-view .cal-day-cell {
    max-height: 200px;
    overflow-y: auto;
}

.cal-month-view .cal-day-appoint .cal-day-appoint-right {
    word-break: break-word !important;
}

body.theme-yellow-light .cal-event-actions a {
    color: #006400;
}

body.theme-default .cal-event-actions a {
    color: #4d2184;
}

body.theme-yellow-light .mat-flat-button.mat-accent-blue.mat-accent,
body.theme-yellow-light .mat-raised-button.mat-accent-blue.mat-accent,
body.theme-yellow-light .mat-fab.mat-accent-blue.mat-accent,
body.theme-yellow-light .mat-mini-fab.mat-accent-blue.mat-accent,
body.theme-blue-gray-dark .mat-flat-button.mat-accent-blue.mat-accent,
body.theme-blue-gray-dark .mat-raised-button.mat-accent-blue.mat-accent,
body.theme-blue-gray-dark .mat-fab.mat-accent-blue.mat-accent,
body.theme-blue-gray-dark.mat-mini-fab.mat-accent-blue.mat-accent {
    background: #039be5 !important;
}

.page-layout.carded.fullwidth>.center>.header {
    overflow-y: auto;
}

body.theme-default .mat-radio-button.mat-accent .mat-radio-inner-circle,
body.theme-pink-dark .mat-radio-button.mat-accent .mat-radio-inner-circle {
    background-color: #4d2184 !important;
}

body.theme-default .mat-fab.mat-accent {
    background-color: #4d2184 !important;
}

body.yellow-light .mat-radio-button.mat-accent .mat-radio-inner-circle,
body.theme-blue-gray-dark .mat-radio-button.mat-accent .mat-radio-inner-circle {
    background-color: #006400 !important;
}

// Column Resizable
.e-float-input.e-control-wrapper:not(.e-input-focus):not(.e-disabled) input:not(:focus):not(:valid)~label.e-float-text:not(.e-label-top) {
    // font-family: Muli, Helvetica Neue, Arial, sans-serif !important;
    font-family: 'Poppins', sans-serif !important;
  //  font-size: 14px;
  background: unset !important;
    transform: translate3d(0, 12px, 0) scale(1);
    // font: 400 11px / 1.125 Muli, Helvetica Neue, Arial, sans-serif;
    font: 300 11px / 1.125 'Poppins', sans-serif;
}

.e-float-input.e-control-wrapper:not(.e-error) input[readonly]~label.e-label-top.e-float-text,
.e-float-input.e-control-wrapper:not(.e-error) input~label.e-label-top.e-float-text,
.e-float-input.e-control-wrapper:not(.e-error).e-input-focus input~label.e-float-text {
    // font-family: Muli, Helvetica Neue, Arial, sans-serif !important;
    font-family: 'Poppins', sans-serif !important;
}

ejs-dropdowntree.e-control {
    width: 100% !important;
}

.rename_flex_div {
    margin: 15px 0;
}

.rename_flex_div .mat-form-field-wrapper {
    padding-bottom: 0;
}

.resizable-table .mat-header-row .mat-header-cell:last-child,
.resizable-table .mat-row .mat-cell:last-child {
   // white-space: nowrap !important;
    overflow: visible !important;
   // display: flex !important;
    flex: auto !important;
    // width: auto !important;
}

body.theme-yellow-light .mat-form-field-appearance-outline.dateinput.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick,
body.theme-default .mat-form-field-appearance-outline.dateinput.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
    color: rgba(0, 0, 0, 0.12) !important;
}

body.theme-yellow-light .dateinput.mat-form-field-invalid .mat-input-element,
body.theme-default .dateinput.mat-form-field-invalid .mat-input-element {
    caret-color: #353a48
}

body.theme-yellow-light .mat-form-field.dateinput.mat-form-field-invalid .mat-form-field-label,
body.theme-default .mat-form-field.dateinput.mat-form-field-invalid .mat-form-field-label {
    color: rgba(0, 0, 0, 0.6) !important;
}

body.theme-blue-gray-dark .mat-form-field.dateinput.mat-form-field-invalid .mat-form-field-label,
body.theme-pink-dark .mat-form-field.dateinput.mat-form-field-invalid .mat-form-field-label {
    color: rgba(255, 255, 255, 0.7);
}

body.theme-default app-silqpay a {
    color: #4d2184 !important;
}

.dateinput .mat-form-field-suffix {
    display: inline-flex !important;

    .highlight_off {
        margin-top: 5px;
    }
}


/** New diary UI **/

body.theme-yellow-light .e-schedule .e-vertical-view .e-header-cells.e-current-day {
    color: #43a047;
}

body.theme-yellow-light .e-schedule .e-vertical-view .e-clone-time-indicator,
body.theme-yellow-light .e-schedule .e-vertical-view .e-current-time {
    color: #43a047;
}

body.theme-yellow-light .e-schedule .e-vertical-view .e-current-timeline {
    border-top: 1px solid #43a047;
}

body.theme-yellow-light .e-schedule .e-vertical-view .e-day-wrapper .e-appointment {
    background: #43a047;
    border: 1px solid #43a047;
}

body.theme-yellow-light .e-schedule .e-vertical-view .e-previous-timeline {
    border-top: 1px dotted #43a047;
}

body.theme-yellow-light .e-schedule .e-month-view .e-appointment {
    background: #43a047;
}

body.theme-yellow-light .e-schedule .e-month-view .e-current-date .e-date-header,
body.theme-yellow-light .e-schedule .e-month-agenda-view .e-current-date .e-date-header {
    background-color: #43a047;
}

body.theme-yellow-light .e-schedule .e-month-view .e-date-header-wrap table td.e-current-day,
body.theme-yellow-light .e-schedule .e-month-agenda-view .e-date-header-wrap table td.e-current-day {
    color: #43a047;
}

body.theme-yellow-light .e-schedule .e-month-view .e-current-date {
    background-color: #e8fde7;
}

body.theme-yellow-light .e-schedule .e-schedule-toolbar .e-active-view .e-tbar-btn-text,
body.theme-yellow-light .e-schedule .e-schedule-toolbar .e-active-view .e-icons {
    color: #43a047;
}

body.theme-default .e-schedule .e-vertical-view .e-header-cells.e-current-day {
    color: #4d2184;
}

body.theme-default .e-schedule .e-vertical-view .e-clone-time-indicator,
body.theme-default .e-schedule .e-vertical-view .e-current-time {
    color: #4d2184;
}

body.theme-default .e-schedule .e-vertical-view .e-current-timeline {
    border-top: 1px solid #4d2184;
}

body.theme-default .e-schedule .e-vertical-view .e-day-wrapper .e-appointment {
    background: #4d2184;
    border: 1px solid #4d2184;
}

body.theme-default .e-schedule .e-vertical-view .e-previous-timeline {
    border-top: 1px dotted #4d2184;
}

body.theme-default .e-schedule .e-month-view .e-appointment {
    background: #4d2184;
}

body.theme-default .e-schedule .e-month-view .e-current-date .e-date-header,
body.theme-default .e-schedule .e-month-agenda-view .e-current-date .e-date-header {
    background-color: #4d2184;
}

body.theme-default .e-schedule .e-month-view .e-date-header-wrap table td.e-current-day,
body.theme-default .e-schedule .e-month-agenda-view .e-date-header-wrap table td.e-current-day {
    color: #4d2184;
}

body.theme-default .e-schedule .e-month-view .e-current-date {
    background-color: #c4abe4;
}

body.theme-default .e-schedule .e-schedule-toolbar .e-active-view .e-tbar-btn-text,
body.theme-default .e-schedule .e-schedule-toolbar .e-active-view .e-icons {
    color: #4d2184;
}

.e-day-view .e-time-cells-wrap .e-schedule-table tr:nth-child(odd) td {
    background-color: rgba(0, 0, 0, .04) !important;
    border-top: none;
    border-bottom-width: thin;
    border-bottom-style: dashed;
}

.e-day-view .e-time-cells-wrap .e-schedule-table tr:nth-child(even) td {
    background-color: #fafafa;
    border-top: none;
    border-bottom-width: thin;
    border-bottom-style: dashed;
}

.e-day-view .e-schedule-table.e-content-table tr:nth-child(odd) td {
    background-color: rgba(0, 0, 0, .04) !important;
    border-top: none;
    border-bottom-width: thin;
    border-bottom-style: dashed;
}

.e-day-view .e-schedule-table.e-content-table tr:nth-child(even) td {
    background-color: #fafafa;
    border-top: none;
    border-bottom-width: thin;
    border-bottom-style: dashed;
}

.e-schedule .e-month-view .e-work-cells {
    height: 200px;
}

body.theme-default .upload_doc_header {
    background: #4d2184;
}

.height_custom_scroll.mat-select-panel.mat-primary {
    min-height: calc(100% + 80px) !important;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.notifications-panel {
    width: 320px;
    min-width: 320px;
    max-width: 320px;
}

app-email-signature {
    .ck.ck-splitbutton>.ck-file-dialog-button.ck-button {
        pointer-events: none !important;
    }
}

.my-calendar-class {
    position: fixed;
    top: 0;
}

.more_tbl_clmn {

    .mat-header-cell,
    .mat-cell {
        padding-left: 20px;
        padding-right: 20px;
    }
}
