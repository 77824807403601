// Import Fuse core library
@import "@fuse/scss/core";
// Import app.theme.scss
@import "app/app.theme";
//for toster
// regular style toast
@import "~ngx-toastr/toastr.css";
//date rang picker
@import "~saturn-datepicker/bundle.css";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-icons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-documenteditor/styles/material.css';
@import '../node_modules/@syncfusion/ej2-richtexteditor/styles/material.css';
@import "../node_modules/@syncfusion/ej2-image-editor/styles/material.css";
@import '../node_modules/@syncfusion/ej2-angular-filemanager/styles/material.css';
 
@import "./assets/scss/fonts";

$fontbase:  "Poppins", serif;

*:not(.mat-icon):not(.e-icons):not(.material-icons){
    font-family: 'Poppins', sans-serif !important;
}

.disabled-click-cursor {
    cursor: not-allowed !important;
}
app-bank-feed {
    display: inline-block !important;
}
vertical-layout-1 #main {
    min-height: 100vh;
}

body.theme-yellow-light fuse-sidebar {
    flex-grow: 1;
    height: 100%;
}

body navbar navbar-vertical-style-1 {
    display: inline-flex !important;
}

navbar navbar-vertical-style-1 .navbar-scroll-container.sidebar_btm_div {
    height: 100% !important;
}

*,
*::after,
*::before {
    box-sizing: border-box !important;
}

.mat-sort-header-content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

mat-nav-list a.disabled-click-cursor {
    pointer-events: none;
    cursor: not-allowed !important;
    text-decoration: none;
    opacity: 0.5;
}

section.extras_tab.disabled-click-cursor {
    pointer-events: none;
    cursor: not-allowed !important;
    text-decoration: none;
    opacity: 0.5;
}

mat-icon.disabled-click-cursor {
    pointer-events: none;
    cursor: not-allowed !important;
    text-decoration: none;
    opacity: 0.5;
}

.px-8 {
    padding-left: 8px;
}

button.disabled-click-cursor {
    pointer-events: none;
    cursor: not-allowed !important;
    text-decoration: none;
    opacity: 0.5;
}

button.mat-menu-item.disabled-click-cursor {
    pointer-events: none;
    cursor: not-allowed !important;
    text-decoration: none;
    opacity: 0.5;
}

button:disabled {
    cursor: not-allowed !important;
}

button.disabled-click {
    pointer-events: none;
    color: rgba(0, 0, 0, 0.26) !important;
    opacity: 0.5;
}

mat-icon.set_icon {
    font-size: 32px !important;
}

.page-layout.carded.fullwidth>.center>.header {
    height: 86px !important;
    min-height: 86px !important;
    max-height: 86px !important;
}

.add_edit_matter_main.popup_main_div .mat-tab-label {
    min-width: 130px !important;
}

.confirm_popup .mat-dialog-title {
    color: #fff;
    margin-bottom: 0;
    padding: 14px 24px;
    background-color: #303540;
}

// .orange-table .mat-row.has-red-row .mat-cell {
//     color: #a71818 !important;
// }
// .orange-table .mat-row.has-orange-row .mat-cell {
//     color: orange !important;
// }
// .orange-table .mat-row.has-Purple-row .mat-cell {
//     color: purple !important;
// }
.cookie_policy_mian {
    height: 42px;
    font-size: 13px;
    text-align: center;
    line-height: 40px;
    color: #fff;
}

.client_text {
    font-size: 18px;
    font-weight: bolder;
}

// ckeditor 5 custome
.ck.ck-toolbar.ck-toolbar_grouping {
    padding-top: 6px;
    padding-bottom: 6px;
    background: lavender;
    /* background: aliceblue; */
    /* border-radius: 37px; */
    /* padding: 10px; */
}

.ck-editor__editable {
    min-height: 230px !important;
}

li.ck.ck-list__item {
    /* padding-bottom: 3px; */
    padding: 7px;
}

.ck.ck-reset.ck-dropdown__panel.ck-dropdown__panel_se.ck-dropdown__panel-visible {
    height: 200px;
    overflow: auto;
}

.ck-content i {
    font-size: 14px !important;
    font-style: italic !important;
    color: black !important;
    font-family: none !important;
}

// .gray_out .mat-form-field-wrapper {
//     background-color:#cccc !important;
// }
// .gray_out_for_poup .mat-form-field-flex {
//     background-color:#cccc !important;
// }
.gray_out.mat-form-field-appearance-outline .mat-form-field-outline-start,
.gray_out.mat-form-field-appearance-outline .mat-form-field-outline-gap,
.gray_out.mat-form-field-appearance-outline .mat-form-field-outline-end {
    background-color: #ddd;
}

.gray_out_for_poup.mat-form-field-appearance-outline .mat-form-field-outline-start,
.gray_out_for_poup.mat-form-field-appearance-outline .mat-form-field-outline-gap,
.gray_out_for_poup.mat-form-field-appearance-outline .mat-form-field-outline-end {
    background-color: #ddd;
}

.readonly_apply.mat-form-field-appearance-outline .mat-form-field-outline-start,
.readonly_apply.mat-form-field-appearance-outline .mat-form-field-outline-gap,
.readonly_apply.mat-form-field-appearance-outline .mat-form-field-outline-end {
    background-color: #ddd;
}

.mat-form-field-disabled.mat-form-field-appearance-outline .mat-form-field-outline-start,
.mat-form-field-disabled.mat-form-field-appearance-outline .mat-form-field-outline-gap,
.mat-form-field-disabled.mat-form-field-appearance-outline .mat-form-field-outline-end {
    background-color: #ddd;
}

.mat-dialog-container .select_matter_main_div .example-containerdata {
    height: auto !important;
    // max-height: calc(100vh - 305px);
    max-height: calc(100vh - 325px);
}

.example-tooltip-font {
    font-size: 15px !important;
}

.schedule-cal-custom table.e-schedule-table.e-outer-table .e-content-wrap {
    // height: calc(100vh - 390px) !important;
}

.schedule-cal-custom table.e-schedule-table.e-outer-table .e-content-wrap .e-work-cells {
    height: auto;
}

.theme-yellow-light .e-more-popup-wrapper .e-appointment {
    background: #43a047;
}

.theme-yellow-light .e-more-popup-wrapper .e-more-event-date-header .e-current-date {
    color: #4c4c4c;
}

.theme-default .e-more-popup-wrapper .e-appointment {
    background: #4d2184;
}

.theme-default .e-more-popup-wrapper .e-more-event-date-header .e-current-date {
    color: #4c4c4c;
}

.schedule-cal-custom.e-schedule .e-vertical-view .e-time-cells-wrap table td,
.schedule-cal-custom.e-schedule .e-vertical-view .e-work-cells {
    height: 36px !important;
}

.schedule-cal-custom table.e-schedule-table.e-outer-table .e-time-cells-wrap {
    // height: calc(100vh - 390px) !important;
}

.schedule-cal-custom .e-month-view table.e-schedule-table.e-outer-table .e-content-wrap {
    // height: calc(100vh - 360px) !important;
}

.custom-dialog-container_dialogBox {
    background-color: #ffff !important;
    height: 100px !important;
    width: 450px !important;
    border-radius: 5px !important;
}

// /* ----------- Non-Retina Screens ----------- */
// @media screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
//     body {
//         zoom: 75%;
//     }
// }
// @media screen and (min-width: 1030px) and (max-width: 1366px) and (-webkit-min-device-pixel-ratio: 1) {
//     body {
//         zoom: 75%;
//     }
// }
// /* ----------- Retina Screens ----------- */
// @media screen and (min-device-width: 1030px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
//     body {
//         zoom: 75%;
//     }
// }
// @media screen and (min-width: 1030px) and (max-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
//     body {
//         zoom: 75%;
//     }
// }

//FOnt controller
body {
    font-size: 12px !important;
}

.mat-form-field {
    // font: 400 11px/1.125 Muli, Helvetica Neue, Arial, sans-serif;
    font: 300 11px/1.125 'Poppins', sans-serif;
}

.mat-cell,
.mat-footer-cell {
    font-size: 11px;
}

.mat-checkbox {
    font-size: 11px;
}

.mat-button,
.mat-raised-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button,
.mat-fab,
.mat-mini-fab {
    font-size: 11px;
}

.mat-dialog-title {
    // font: 600 17px/32px Muli, Helvetica Neue, Arial, sans-serif;
    font: 300 17px/32px 'Poppins', sans-serif;
}

.top_header .ribbon-container .large-button .large-button-container .main-text {
    font-size: 11px !important;
    padding-top: 5px;
}

.top_header .mat-list-item span {
    font-size: 11px !important;
}

.mat-list-item-content {
    color: black;
    font-size: 11px !important;
}

.heading-text {
    font-size: 18px !important;
}

h1 {
    font-size: 22px !important;
}

.h1 {
    font-size: 21px !important;
}

.h2 {
    font-size: 18px !important;
}

h3 {
    font-size: 14px !important;
}

.h4 {
    font-size: 12px !important;
}

span.mat-display-1.main_name.mb-0 {
    font-size: 14px !important;
}


.secondary_label {
    font-size: 16px !important;
}

.client_text {
    font-size: 16px !important;
}

.secondry_text {
    font-size: 14px !important;
}

.secondary-text {
    font-size: 14px !important;
}

.e-grid .e-rowcell {
    font-size: 11px !important;
}

.mat-body,
.mat-body-1,
.mat-typography {
    // font: 400 11px/20px Muli, Helvetica Neue, Arial, sans-serif;
    font: 300 11px/20px 'Poppins', sans-serif;
}

.mat-tab-label,
.mat-tab-link {
    font-size: 12px !important;
}

.mat-expansion-panel-header {
    font-size: 12px !important;
}

.grid-overlay-msg {
    font-size: 16px !important;
}

.mat-menu-item {
    font-size: 12px !important;
}

.mat-slide-toggle {
    font-size: 12px !important;
}

.mat-radio-group .mat-radio-button {
    font-size: 12px !important;
}

app-login #login #login-form-wrapper #login-form .title {
    font-size: 18px !important;
}

app-sign-up #login #login-form-wrapper #login-form .title {
    font-size: 18px !important;
}

app-forgot-password #forgot-password #forgot-password-form-wrapper #forgot-password-form .title {
    font-size: 18px !important;
}

//for mat icon
.mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-icon {
    position: relative;
    // top: 0.25rem;
    // top: 8px;
}

.mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-icon img {
    display: block;
    max-width: 75%;
    padding-top: 4px;
    // position: relative;
    // bottom: 5px;
}

.mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .set_icon img {
    // display: block;
    max-width: 60%;
    // padding-top: 0px;
    padding-top: 9px;
    // position: relative;
    // bottom: 5px;
}

.set-inline-time .resizable-table mat-footer-row,
mat-row {
    min-height: 30px !important;
}


.mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding-bottom: 0 !important;
}

// .footer_change .mat-form-field.mat-form-field-type-mat-select .mat-form-field-infix {
//     border-top: 0;
//     padding: 0;
// }
.mat-paginator-container {
    min-height: 40px !important;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
    bottom: -3px;
}

.mat-header-cell:last-child, .mat-cell:last-child{
    margin-right: 90px;
    border-color: rgb(176 176 176 / 37%);
}

.resizable-table .mat-header-cell {
    min-height: 40px;
}

mat-header-row {
    min-height: 40px !important;
}

.mat-paginator-page-size {
    align-items: center !important;

    .mat-select {
        margin: 0 0 11px 0;
    }
}

// .mat-paginator-page-size-select {
//     margin: 0 !important;
// }
mat-header-row {
    min-height: 40px !important;
}

.resizable-table .mat-header-cell {
    line-height: 40px;
}

.top_right_action.matter_right_action {
    top: -6px;
    height: 40px;
    line-height: 40px;
}

// .sticky_search_div .mat-form-field-appearance-outline .mat-form-field-infix {
//     padding: 0.3em 0 0.3em 0;
// }

toolbar .top_header .ribbon-container .large-button .large-button-container .main-text {
    font-size: 12px;
}

toolbar .ribbon-container .large-button .large-button-container .main-icon img {
    max-width: 27px;
}

.small_serach .mat-form-field-label-wrapper {
    top: -1.00375em;
}

.small_serach.mat-form-field-appearance-outline .mat-form-field-suffix {
    top: 0.05em !important;
}


.search_input .mat-input-element {
    line-height: 25px;
}

.search_input .mat-form-field-hide-placeholder .mat-form-field-label-wrapper {
    line-height: 29px;
}

.search_input .mat-form-field-infix {
    min-height: 40px;
}

.search_input .small_serach.mat-form-field-appearance-outline .mat-form-field-flex {
    align-items: center !important;
}

.search_input .search.small_serach.mat-form-field-appearance-outline .mat-form-field-suffix {
    top: 5px !important;
}

.search_input .small_serach {
    padding: 0 !important;
    padding-left: 15px !important;
}

.main_bodywrap .left .active-menu .mat-list-item-content {
    color: #fff !important;
}

.matter-serach-sm {
    .search {
        // padding: 10px !important;
    }
}

.matter-serach-sm form>* {
    width: auto !important;
}

#genetate_tablewrap mat-cell.mat-cell:first-of-type {
    width: 40px;
    max-width: 40px;
    padding-right: 0px;
}

#genetate_tablewrap mat-header-cell.mat-header-cell:first-of-type {
    width: 50px;
    max-width: 50px;
    padding-right: 0px;
}

#genetate_tablewrap {
    max-width: 800px;
}

.active_management .manage-tab:first-of-type {
    max-width: 13% !important;
}

.active_management .manage-tab {
    max-width: 10% !important;
}

.active_management .manage-tab.management_six,
.header_menu .active_management .manage-tab.management_five {
    max-width: 9% !important;
}

#court-form .court-listing .secondary-text {
    position: fixed !important;
}

@media screen and (max-width: 1279px) {
    .matter-serach-sm>form {
        display: flex;
    }

    .sticky_search_div.matter-serach-sm {
        padding-left: 0 !important;
    }
}

::ng-deep {
    .set-inline-time .mat-column-CREATEDDATEANDTIME>* {
        white-space: pre;
    }

    .set-inline-time .mat-column-MODIFIEDDATEANDTIME>* {
        white-space: pre;
    }

    .set-inline-time .resizable-table mat-footer-row,
    mat-row {
        min-height: 40px !important;

        mat-cell {
            display: flex !important;
            align-items: center;
        }
    }
}


.dot_controller {
    top: -6px !important;
    height: 40px !important;
    line-height: 40px !important;

    .mat-icon-button {
        width: 40px !important;
        height: 40px !important;
        line-height: 40px !important;
    }
}

//End Font controller

.date-block {
    .mat-form-field-wrapper {
        position: absolute;
    }

    .mat-form-field-infix {
        display: none;
    }

    .mat-form-field-flex {
        padding: 0;
    }
}

.date-togglebtn {
    background-color: #edebeb !important;
    border-radius: 40px !important;
    box-shadow: none !important;
    height: 36px;
    display: flex !important;
    align-items: center;
    justify-content: center;

    .mat-button-wrapper {
        display: flex;
        align-items: center;

        svg {
            width: 20px;
            margin-right: 6px;
        }
    }
}

.status-selectwrap {
    width: auto !important;
    min-width: 70px;

    .mat-select-trigger {
        width: 100%;

        .mat-select-value {
            white-space: inherit;
            text-overflow: inherit;
            display: inherit;
            padding: 0 16px;
            font-size: 11px;
        }
    }

    .mat-select-arrow-wrapper {
        display: none;
    }
}

// .innerscrollmain {
//     .mat-table.mat-table.ng-trigger.ng-trigger-animateStagger {
//         &.table-wrapper {
//             // max-height: calc(100vh - 420px) !important;
//             max-height: calc(100vh - 275px) !important;
//             overflow: auto !important;
//             display: block !important;
//         }
//     }
// }

.user-editorwrap {
    // .ck-editor__editable_inline {
        min-height: 300px !important;
        max-height: 300px !important;
    // }
}
.user-editorwrap .e-rte-content{
        height: 244px !important;
            overflow: auto;
}

.ckeditor5_mode>.ck .ck-widget,
.ck .ck-widget.ck-widget_with-selection-handle {
    float: left !important;
}

%icon-styling {
    color: #aaa;
    width: 15px !important;
    height: 15px !important;
    min-width: auto;
    min-height: auto;
    display: inline-block;
    align-items: center;
    font-size: 15px !important;
    justify-content: center;
    margin-right: 5px;
}

.filter-menuwrap {
    border-radius: 8px;
    margin: 6px 0;

    .buttonwith-text {
        &:not(.no-icon) {
            .mat-button-wrapper {
                padding-left: 21px;
            }
        }

        &.no-icon {
            .mat-button-wrapper {
                padding-left: 3px;
            }
        }
    }

    .mat-button-wrapper {
        display: flex;
        align-items: center;

        .mat-icon {
            @extend %icon-styling;
        }
    }

    .add-filterwrap {
        .mat-button-wrapper {
            padding-left: 2px;
        }
    }
}

.badge-ic {
    @extend %icon-styling;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 8px;
    z-index: 1;
    cursor: pointer;

}

.filter-menublock {
    >* {
        margin: 2px 10px 2px 0;
    }
}

.back-tick {
    transform: rotate(-90deg);
    width: 18px;
    height: 18px;
    display: inline-flex;
    align-items: center;
    position: relative;
    top: -10px;
    flex-shrink: 0;
    margin-right: 10px;
}

.field-box {
    >* {
        &:not(:last-child) {
            margin-right: 6px;
        }
    }
}

.time-selection {
    margin-bottom: 15px;

    .mat-radio-button {
        &:not(:last-child) {
            margin-right: 10px;
        }

        .mat-radio-label-content {
            font-weight: bold;
        }
    }

}

.tooltip-wrap {
    position: absolute;
    top: -5px;
    right: -8px;
    background: #e5e5e5;
    bottom: 1px;
    display: flex;
    align-items: center;
    padding: 10px 12px;
    border-radius: 0 5px 5px 0;

    input {
        background: transparent;
        padding: 0;
        width: 60px;
    }

    &::before {
        content: '';
        display: block;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-right: 9px solid #e5e5e5;
        position: absolute;
        left: -9px;
    }

    mat-icon {
        width: 15px;
        height: 15px;
        min-width: auto;
        min-height: auto;
        font-size: 16px;
        margin-left: 8px;
    }
}

.listitem-text {
    white-space: inherit !important;
}

.cdk-overlay-container {
    .cdk-overlay-dark-backdrop.cdk-overlay-backdrop-showing {
        &~.cdk-global-overlay-wrapper {
            .cdk-overlay-pane {
                flex-grow: 1;

                .mat-tab-header {
                    min-width: 100%;
                }
            }
        }
    }
}

.email-ckeditorblock {
    .ck.ck-editor__editable_inline {
        overflow: auto !important;
        max-height: calc(100vh - 440px);
        min-height: 150px;
    }
}

.invoice_add_dialoug_form {

    mat-row,
    mat-header-row,
    tr {
        border-bottom: 0;

        mat-cell,
        mat-header-cell {
            border-top: 1px solid rgba(0, 0, 0, 0.12);
        }
    }

    mat-header-row {
        mat-header-cell {
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        }
    }
}

.headingtr-block {

    .mat-cell,
    .mat-header-cell {
        border-top: 0;
        border-bottom: 0;
    }
}


// ends here ~ global filter design
.mt-55 {
    margin-top: 55px;
}

.fileupload-block {
    border: 2px dashed gray !important;
    border-radius: 10px !important;
    display: flex;
    align-items: center;
    height: 120px;
    justify-content: center;

    input[type=file] {
        display: none;
    }

    .textLink {
        background-color: #43a047;
        color: #fff;
        padding: 10px;
        border-radius: 5px;
        box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    }
}

.flex-column {
    flex-direction: column !important;
}

.itemimge {
    position: relative !important;
    width: 195px;
    margin-top: 22px;

    .mat-icon {
        width: 26px;
        height: 26px;
        background: rgba(0, 0, 0, 0.38);
        border-radius: 50%;
        color: #fff;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        top: -11px !important;
        position: absolute;
        right: -10px;
        font-size: 18px;
        cursor: pointer;
    }
}

.mb-30 {
    margin-bottom: 30px;
}

.filterscroll {
    &:not(:empty) {
        padding-top: 0;
        padding-bottom: 0;
        max-height: 230px;
        overflow: auto;

    }
}


/* Starts Styling for matters Component */
.list_sidebar_right_child {
    background-color: #fff !important;
    border-radius: 8px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
    border-bottom: 1px solid #999;
}

.mt-90 {
    margin-top: 90px;
}

.mt-75 {
    margin-top: 75px;
}

.mt-110 {
    margin-top: 110px;
}

.appCues-blankSpace {
    position: absolute;
    top: 12px;
    right: 20px;
    width: 48px;
    height: 28px;

    a {
        font-size: 20px;
        padding: 5px;
        // visibility: hidden;
        color: transparent !important;
        cursor: default;
    }
}

.toggle-smallbtn {
    position: relative;
    width: 100%;

    a {
        float: right;
        padding: 4px;
        font-size: 22px;
        visibility: hidden;
    }
}

.togglechecksol.mat-checked .mat-slide-toggle-thumb {
    background-color: #43a047 !important;
}

.togglechecksol.mat-checked .mat-slide-toggle-bar {
    background-color: #c0e4c2 !important;
}

.togglecheckbar.mat-checked .mat-slide-toggle-thumb {
    background-color: #4E2083 !important;
}

.togglecheckbar.mat-checked .mat-slide-toggle-bar {
    background-color: #bda5d8 !important;
}


.mat-button.buttonwith-text {
    border: 1px dashed #ccc;
    border-radius: 30px;
    padding: 3px 6px;
    min-width: auto;
    transition: border 0.2s;
    display: flex;
    align-items: center;

    &.active {
        border-style: solid;
    }
}

.marginleft-0 {
    margin-left: 0 !important;
}

.filter-applytext {
    border-left: 1px solid rgba(0, 0, 0, 0.12);
    padding-left: 8px;
    margin-left: 8px;
    // color: #43a047;
}

.solicitor-container {
    .filter-applytext {
        color: #43a047;
    }

    .filter-button {

        .mat-button-wrapper {
            color: #43a047 !important;
            font-weight: bolder;
            font-size: 12px;
        }

    }

    button.addbutton {
        color: #43a047 !important;
    }
}

.barrister-container {
    .filter-applytext {
        color: #4E2083 !important;
    }

    .filter-button {

        .mat-button-wrapper {
            color: #4E2083 !important;
        }

    }

    .addbutton {
        color: #4E2083 !important;
    }
}

.barrister-container-withdarkmode {
    .filter-applytext {
        color: #b187e2;
    }

    .filter-button {
        ::ng-deep {
            .mat-button-wrapper {
                color: #b187e2;
            }
        }
    }

    .addbutton {
        color: #b187e2 !important;
    }
}

.solicitor-container-withdarkmode {
    .filter-applytext {
        color: #87CD8A;
    }

    .filter-button {
        ::ng-deep {
            .mat-button-wrapper {
                color: #87CD8A;
            }
        }
    }

    button.addbutton {
        color: #87CD8A !important;
    }
}

.display-flex {
    display: flex;
}

.pl-10 {
    padding-left: 10px;
}

.pr-10 {
    padding-left: 10px;
}

.justify-content-center {
    justify-content: center;
}

.align-center {
    align-items: center;
}

.toggleSize {
    transform: scale(0.9);
}

.display-inlineflex {
    display: inline-flex;
}

.display-inline {
    display: inline;
}

.filter-checks {
    padding: 8px 0;
    display: inline-block;
}

.fl-left {
    float: left;
}

.fl-revert {
    float: revert;
}

.clearfix {
    &::after {
        content: '';
        display: block;
        clear: both;
    }
}

.mat-menu-content {
    &.mat-menu-content.active {
        &.mat-menu-content_height {
            max-height: 130px !important;
        }
    }
}

.fullsize-table {
    height: auto !important;
    min-height: calc(100vh - 420px);
}

.date-dropdown-selector {
    padding-top: 3.1px;
    line-height: 14px;
}

.date-filter-field {
    line-height: 17px;
    // margin-top: -2px;
}

.general-journalwrap {

    .togglechecksol {
        margin-right: 10px;
    }
}

.flex-shrink-0 {
    flex-shrink: 0;
}

.nodata-table {
    width: 100%;
}

.filter-button {
    padding: 0 !important;
    background-color: transparent !important;

    .mat-button-wrapper {
        color: #43a047;
        font-size: 12px;
        font-weight: bold;
    }
}

.menu-submenu {
    .set-date {
        &:first-child {
            .mat-form-field-wrapper {
                .mat-form-field-infix {
                    height: 31.66px !important;
                }
            }
        }

        &:nth-child(2) {
            .mat-form-field-wrapper {
                margin-top: 3px !important;

                .mat-form-field-infix {
                    height: 28.91px !important;
                }
            }
        }
    }
}

.searchoverlay,
.searchradio {
    &+div {
        .mat-menu-panel {
            box-shadow: 0px 3px 2px #dedede;
            padding: 15px 12px 12px;
            position: relative;
            overflow: visible;

            .mat-form-field-type-mat-input {
                .mat-form-field-infix {
                    width: auto;
                    padding: 5px 0;
                }
            }

            .mat-form-field-type-mat-select {
                .mat-form-field-infix {
                    width: auto;
                    padding: 10px 0;
                    border-top: 0;
                    margin-top: 0;

                }

                .mat-form-field-outline {
                    top: 0;
                }

                .mat-select-arrow-wrapper {
                    transform: translateY(0);
                }
            }
        }

        .filterscroll {
            &:not(:empty) {
                padding-top: 0;
                padding-bottom: 0;
                max-height: 230px;
                overflow: auto;

            }
        }

        .mat-menu-content {
            &:not(:empty) {
                padding-top: 0;
                padding-bottom: 0;
                // max-height: 230px;
                // overflow: auto;

            }

            &.active {
                max-height: 170px;
            }
        }

        .filter-title {
            margin-top: 0;
            font-weight: 700;
            margin-bottom: 10px;
            // color: #000;
            display: flex;
            align-items: center;

            &>.mat-icon {
                width: 33px;
                height: 24px;
                font-size: 17px;
                border-radius: 4px;
                border: 1px solid #ccc;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                flex-shrink: 0;
                margin-right: 6px;
            }
        }

        .width-full {
            width: 100%;
        }

        .filter-radio-group {
            mat-radio-button {
                width: 100%;
                padding: 5px 0px;

                .mat-radio-label {
                    .mat-radio-container {
                        display: none;

                        .mat-radio-outer-circle {
                            display: none;
                        }

                        .mat-radio-inner-circle {
                            display: none;
                        }
                    }
                }
            }

            mat-radio-button.mat-radio-checked {
                background: #80808040;

            }
        }
    }
}

.searchradio {
    &+div {
        .mat-menu-panel {
            max-width: 180px;
        }
    }
}

.pb-fieldspace {
    padding-bottom: 1.34375em;
}

.mt-10 {
    margin-top: 10px;
}

.pt-10 {
    padding-top: 10px;
}

fuse-navigation {
    &>.nav {
        display: flex;
        flex-direction: column;

        &>.asidebottom-block {
            margin-top: auto;
            display: flex;
            justify-content: flex-start;
            margin-bottom: -10px;
        }
    }
}

// app cues css

.matter-side-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.form-div-side {
    width: 97%;
}

.rigth-div {
    max-width: 3%;
    width: 42px;
    height: 40px;

}

.text-R-align {
    text-align: right;
}

.text-L-align {
    text-align: left;
}

.text-C-align {
    text-align: center;
}

.text--align {
    text-align: center;
}

.inputblock-wrap {
    bottom: auto !important;
}

.filtermenublock-wrap {
    >* {
        width: calc(16.66% - 10px);

        .mat-button.buttonwith-text {
            width: 100%;
        }
    }
}

@media screen and (max-width: 1550px) {
    .filtermenublock-wrap {
        >* {
            width: calc(20% - 10px);
        }
    }
}

@media screen and (max-width: 1440px) {
    .filtermenublock-wrap {
        >* {
            width: calc(33.33% - 10px);
        }
    }
}

@media screen and (max-width: 1280px) {
    .filtermenublock-wrap {
        >* {
            width: calc(25% - 10px);
        }
    }
}

@media screen and (max-width: 1180px) {
    .filtermenublock-wrap {
        >* {
            width: calc(33.33% - 10px);
        }
    }
}

// navbar {
//     background: #33353d;
// }
.calender-btn {
    .mat-icon-button {
        width: auto;
        height: auto;
        min-width: auto;
    }

    .mat-input-element {
        bottom: 0px !important;
        text-align: left;
    }

    .calenderbtn-icon {

        .mat-button-wrapper {
            padding-left: 0 !important;
        }
    }

    .mat-datepicker-toggle-default-icon {
        width: 16px;
        height: 16px;
    }
}


//______________________________________ Importent for resize mat-table
.resize-handle-right {
    height: 100%;
    cursor: col-resize;
    width: 25px;
    right: -34px;
    position: absolute;
    pointer-events: all;
    z-index: 1;
}

.resizable-table .mat-table .mat-header-cell {
    overflow: inherit;
}
.resizable-table .mat-header-row .mat-header-cell:last-child {   
    border-right: 1px solid rgba(176, 176, 176, 0.4) !important;
}
.header_title {
    padding: 0px 20px 0px 20px !important;

    .desc_cell .mat-sort-header-content {
        padding: 0px 20px;
    }
}

.mat-table .mat-header-cell {
    border-right: 1px solid rgb(176 176 176 / 9%)
}

.mat-sort-header-content {
    overflow: hidden;

}

.mat-sort-header-container {

    width: 100%;
}

.checkbox_width {
    width: 57px !important;
}

// .mat-table .mat-header-cell:not(:first-child){
//     pointer-events: none;
//     overflow: visible;
// }
// .mat-sort-header-arrow{
//     pointer-events: all;
// }
//_______________________________________ Importent for resize mat-table


.custom-link-hyperlink {
    //color: blue;
    color: #43a047 !important;
    text-decoration: underline;
}

.custom-link-hyperlink-withdarkmode {
    //color: blue;
    color: #87CD8A !important;
    text-decoration: underline;
}

.custom-link-hyperlink-barrister {
    //color: blue;
    color: #4E2083 !important;
    text-decoration: underline;
}

.custom-link-hyperlink-barrister-with-darkmode {
    //color: blue;
    color: #c9aceb !important;
    text-decoration: underline;
}


// app-matters-list .one .mat-table {
//     overflow: auto !important;
//     max-height: 635px !important;
// }


// src/styles.scss
:root {
    --matter-details-menu-width-open: 200px;
    --matter-details-menu-width-closed: 70px;
    --spaceRight: 70px;
}

.matter-details-sidebar {
    .mat-drawer {
        box-sizing: content-box;
        width: var(--matter-details-menu-width-open);
        transition: all 0.3s ease-in-out !important;
    }

    .mat-drawer-content {
        // transform: translateX(200px);
        margin-left: var(--matter-details-menu-width-open) !important;
        transition: all 0.3s ease-in-out !important;
    }

    &.container-closed {
        .mat-drawer {
            width: var(--matter-details-menu-width-closed);
        }

        .mat-drawer-content {
            // transform: translateX(64px) !important;
            margin-left: var(--matter-details-menu-width-closed) !important;
        }
    }
}

.mat-drawer-container {
    width: 100%;
}

.matter-details-sidebar {
    .mat-drawer-inner-container {
        overflow-x: hidden !important;
    }

    .matter-details-side-nav {
        ::-webkit-scrollbar {
            width: 1px;
        }

        ::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        }

        ::-webkit-scrollbar-thumb {
            background-color: darkgrey;
            outline: 1px solid slategrey;
        }
    }


}


.pointer-cursor {
    cursor: pointer;
}

.matter-detail-text {
    color: var(--textColor) !important;

    .cmn_active_tab & {
        color: var(--textActiveColor) !important;
    }
}

// .theme-blue-gray-dark a .matter-detail-text {
//     color: #fff !important ;
// }

// .theme-pink-dark a .matter-detail-text {
//     color: #fff !important ;
// }

// .theme-yellow-light a .matter-detail-text {
//     color: #222;
// }

// .theme-default a .matter-detail-text {
//     color: #222;
// }
.theme-blue-gray-dark a .matter-detail-text-link {
    color: #fff;
}

.theme-pink-dark a .matter-detail-text-link {
    color: #fff;
}

// body.theme-default a{
//     color: #fff !important;
// }

body.theme-pink-dark a {
    color: #fff !important;
}


.sidebar-container .mat-nav-link {
    // display: flex;
    // align-items: center;
    // border-radius: 0 20px 20px 0;
    // overflow: hidden;
    // height: 34px;
    // padding: 5px 8px;
    // margin-bottom: 5px;
    display: flex;
    align-items: center;
    border-radius: 0 20px 20px 0;
    overflow: hidden;
    // height: 40px;
    height: 35px;
    padding: 5px 11px;
    margin-bottom: 5px;
}

.container-closed  .sidebar-container .mat-nav-link {
    // display: flex;
    // align-items: center;
    // border-radius: 0 20px 20px 0;
    // overflow: hidden;
    // height: 34px;
    // padding: 5px 8px;
    // margin-bottom: 5px;
    border-radius: 20px;
}

.sidebar-container .mat-nav-link .mat-icon {
    display: inline-flex;
    align-items: center;
}

.matter-dashboard-slide-toggle .dashboard-slide-toggle .mat-slide-toggle-label .mat-slide-toggle-bar {
    width: 33px !important;
    height: 14px !important;
}

.matter-dashboard-slide-toggle .dashboard-slide-toggle .mat-slide-toggle-label .mat-slide-toggle-bar .mat-slide-toggle-thumb {
    height: 17px !important;
    width: 17px !important;
}

.matter-dashboard-slide-toggle .dashboard-slide-toggle .mat-slide-toggle-thumb-container {
    top: -2px !important;
}

.justify-content-end {
    justify-content: end;
}

.matter-dashboard-slide-toggle {
    padding: 5px 0px;
}

.matter-absolute {
    position: absolute;
    right: 10px;
    top: 21px
}

.filter-radio-group {
    mat-radio-button {
        width: 100%;
        padding: 5px 0px;

        .mat-radio-label {
            .mat-radio-container {
                display: none;

                .mat-radio-outer-circle {
                    display: none;
                }

                .mat-radio-inner-circle {
                    display: none;
                }
            }
        }
    }

    mat-radio-button.mat-radio-checked {
        background: #80808040;

    }
}

.filter-radio-group {
    display: flex;
    flex-direction: column;
    // margin: 15px 0;
    align-items: flex-start;
    margin-bottom: -5px;
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
}

.flex-wrap {
    flex-wrap: wrap;
}

.height-100 {
    height: 100% !important;
}

.estat-asset-list {
    height: calc(57vh - 10px);
}

.estat-asset-list-tag {
    display: flex;
    height: 100%;
    font-size: 20px;
    justify-content: center;
    align-items: center;
}

.firm-dashboard {
    overflow: inherit;
}

app-dashboard {
    overflow: auto;
    height: calc(100vh - 195px);
    display: block;
}



.fs-11 {
    font-size: 11px !important;
}

.settlment-height {
    .mat-tab-body-content {
        overflow: hidden;
    }
}

.height-425 {
    overflow: auto !important;
    max-height: 425px !important;
}

.position-system {
    position: absolute;
    right: 20px;
    bottom: 20px;
}

.p-010 {
    padding: 0px 10px !important;
}

.pr-24 {
    padding: 0px 0px 0px 24px !important;
}

.raidus-0 {
    border-radius: 0px !important;
}

// body.theme-yellow-theme-blue-gray-dark .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
//     background-color: #43a047 !important;
// }
// body.theme-yellow-theme-blue-gray-dark .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
//     background-color: rgba(67, 160, 71, 0.54) !important;
// }

.e-schedule {
    width: 100%;
    height: calc(100vh - 300px);
    overflow: auto;
}

#calendar .content {
    overflow: hidden !important;
}

.main-tabletr {
    overflow: hidden;
    height: calc(100vh - 300px);
}

.schedule-cal-custom .e-month-view table.e-schedule-table.e-outer-table .e-content-wrap {
    // height: auto;
    // min-height: calc(100vh - 450px);
}

.e-schedule-toolbar-container {
    position: sticky;
    top: 0;
    z-index: 9;
}

.title-withswitch {
    display: flex !important;
    flex-wrap: wrap !important;
    align-items: center !important;

    .mat-slide-toggle {
        margin-left: auto !important;
        margin-right: 20px !important;
    }
}

.add-btn-contact {
    margin-left: -2px;
    position: relative;
    bottom: 7px !important;
    color: #43A047;
    cursor: pointer;
}

#toolbarMatGroup {

    // .mat-tab-list{
    //     @media screen and (min-width: 1200px) {
    //         transform: translateX(0) !important;
    //     }
    // }
    .mat-tab-label-container,
    .mat-tab-group {
        width: 100%;
    }

    .mat-tab-header {
        width: 100% !important;
        padding-right: 285px;
    }
}

body.theme-blue-gray-dark .sticky {
    position: sticky;
    top: 0;
    z-index: 9999;
    background: #272626;
    border-bottom: 10px solid #272626;
}

body.theme-yellow-light .sticky {
    position: sticky;
    top: 0;
    z-index: 9999;
    background: #fff;
    border-bottom: 10px solid #F5F5F5;
}

@import '@/../assets/scss/theme';

mat-card {

    .md-drppicker,
    .calendar-table,
    .md-drppicker td.off,
    .md-drppicker td.off.in-range,
    .md-drppicker td.off.start-date,
    .md-drppicker td.off.end-date {
        background-color: var(--cardColor) !important;
        border-color: var(--cardColor) !important;
    }

    .md-drppicker th {
        color: var(--tableColor);
    }

    .md-drppicker td.available.prev,
    .md-drppicker th.available.prev,
    .md-drppicker td.available.next,
    .md-drppicker th.available.next {
        filter: var(--filter);
    }
}

.matter-details-active-menu {
    background: var(--btnColor) !important;
}

.iconbtn-plus {
    .mat-button-wrapper {
        background: var(--btnColorBG);
        padding: 0;
        width: 19px;
        display: flex;
        border-radius: 50%;
        height: 19px;
        margin: 0 auto;

        mat-icon {
            padding: 0;
            position: relative;
            left: -2px;
            top: -2px;
            color: var(--btnColor);

            // padding: 0;
            // position: relative;
            // left: -3px;
            // top: -2px;
            // margin-right: -3px;
            // color: var(--btnColor);
        }
    }
}

.plus-middle {
    position: relative;
    top: -7px;
    flex-shrink: 0;
}

.width-100 {
    width: 100% !important;
}

.padding-bottom-0 .mat-form-field-wrapper {
    padding-bottom: 0 !important;
}

.product-app {
    place-content: baseline !important;
    overflow: auto !important;
    max-height: 350px !important;
}

.line-clamp3 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.margin-x-header-0 {
    .mat-card-header-text {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}


.justify-content-between {
    justify-content: space-between;
}

.align-items-center {
    align-items: center;
}

.text-ellipsis {
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.overflow-auto {
    overflow: auto;
}

.cardblock {
    color: var(--text-secondary-rgb) !important;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.4) !important;
    overflow: hidden;
    display: flex !important;
    border: 1px solid transparent !important;
    transition: all 0.5s ease;

    .mat-card-header {
        .mat-card-title {
            font-size: 16px;

        }
    }

    &.active {
        border-color: var(--btnColor) !important;
    }

    &.disabled {
        &:after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background: rgb(0 0 0 / 10%);
            z-index: 2;
            cursor: no-drop;
        }
    }

    .mat-card-footer {
        position: relative;
        padding: 16px 1.5rem;
        display: flex;
        margin-top: auto;
        background-color: #00800014;
    }


    .mat-card-header-text {
        margin: 0 !important;
        color: var(--textColor);
        @extend .text-ellipsis;
    }

    .mat-card-content {
        font-size: 12px;
    }

    .mat-progress-bar {
        top: 0;
        bottom: auto;
        left: 0;
        right: 10px;
        width: auto;

        .mat-progress-bar-fill::after {
            background-color: var(--btnColor);
        }
    }

    .mat-stroked-button {
        border-radius: 50px;
        font-size: 12px;
        display: flex;
        align-items: center;
        padding: 6px 20px 5px;
        cursor: pointer;
    }

    .ml-05 {
        margin-left: 5px;
    }
}

.ml-10 {
    margin-left: 10px;
}

.pr-10 {
    padding-right: 13px;
}

.gap-10 {
    gap: 10px;
}
app-legal-details .legal-detail-overflow .example-containerdata {
    height: calc(100vh - 400px) !important;
}

// .e-richtexteditor .e-rte-content .e-content,
// .e-richtexteditor .e-rte-content .e-content * {
//     background: revert !important;
//         font-style: revert !important;
//         font-family: revert !important;
//         font-size: revert !important;
//         font-weight: revert !important;
//         background-color: revert !important;
//         color: revert !important;
// }
.e-gridheader.e-lib.e-draggable.e-droppable
{
    position: absolute;
    pointer-events: none;
    visibility: hidden;
}
.custom-menu {
    max-height: 350px !important;
        overflow: auto !important;
}
.setupguide-active{
    .main-container{
        padding-right: var(--spaceRight) !important;
    }
}
.gap-5 {
    gap: 5px;
}
.gap-15 {
    gap: 15px;
}
.gap-20 {
    gap: 20px;
}
.gap-25 {
    gap: 25px;
}
 
.relative {
    position: relative; 
}
.d-flex {
    display: flex;
}
.e-de-tool-ctnr-properties-pane{
 >div{
    > div{
        height: calc(100vh - 320px) !important;
    }
 }
}
.e-documenteditorcontainer{
    height: calc(100vh - 200px) !important;
}

.e-dropdown-popup ul {
    height: 250px !important;
    overflow-y: auto !important;
    /* border: none; */
    /* border-radius: 0; */
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12);
    box-sizing: border-box;
    font-size: 14px;
    font-weight: normal;
    list-style-image: none;
    list-style-position: outside;
    list-style-type: none;
    margin: 0;
    min-width: 120px;
    padding: 8px 0;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    white-space: nowrap;
}
    body.theme-yellow-light .mat-progress-bar-fill::after {
        background-color: #028263 !important;
    }

    body.theme-yellow-light .mat-progress-bar-buffer {
        background-color: #b9e21d !important;
    }
    .e-dialog .e-dlg-header-content + .e-dlg-content table{
        width: 100%;
    }
    .e-fe-popup.e-dialog td:last-child{
        padding-right: 0;
    }
    .e-fe-popup.e-dialog td{
        white-space: normal;
        // padding-right: 0;
    }


    