@mixin forgot-password-2-theme($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);
    forgot-password-2 {
        #forgot-password {
            #forgot-password-intro {
                color: white;
            }
            #forgot-password-form-wrapper {
                @if ($is-dark) {
                    background: mat-color($fuse-navy, 600);
                }
                @else {
                    background: map-get($background, card);
                }
            }
        }
    }
}