@mixin toolbar-theme($theme) {

    $foreground: map-get($theme, foreground);

    toolbar {

        .toolbar-separator {
            background: map-get($foreground, divider);
        }
    }
}

// .selUserMenuItem {
//     color: black !important;
// }

// .userMenuContainer {
//     padding-top: 8px;
//     width: 120px;

//     .mat-icon {
//         margin-right: 5px;
//         line-height: 10px;
//         margin-bottom: -8px;
//     }

//     .user-menu-icon {
//         height: 20px;
//         width: 20px;
//         cursor: pointer;
//     }

//     .nav-item-link {
//         font-size: 11px;
//         cursor: pointer;
//     }
// }


// .User-Loggedon-dialog .mat-dialog-content {
//     min-height: 310px;
// }
