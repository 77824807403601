body.theme-blue-gray-dark toolbar .mat-toolbar.top_header {
    background: inherit !important;
}

body.theme-blue-gray-dark toolbar section.ribbon-container.ng-star-inserted mat-card {
    background: #424242 !important;
}

body.theme-blue-gray-dark toolbar .mat-tab-body section.ribbon-container {
    background: #424242 !important;
}

body.theme-blue-gray-dark .accent {
    background-color: #006400 !important;
}

body.theme-blue-gray-dark .sticky_search_div {
    background-color: transparent !important;
}

body.theme-blue-gray-dark .top_right_action,
body.theme-blue-gray-dark .top_right_action_billing_position {
    background-color: #424242 !important;
    // right: 10px;
}

body.theme-blue-gray-dark .list_sidebar_right_child {
    background-color: #424242 !important;
}

body.theme-blue-gray-dark .p-24.side_header {
    background-color: #424242 !important;
}

body.theme-blue-gray-dark .mat-list-item-content {
    color: #fff;
}

body.theme-blue-gray-dark .row-text-colore {
    background-color: rgb(217, 217, 217) !important;
}

body.theme-blue-gray-dark .mat-input-element {
    caret-color: #fff !important;
}

body.theme-blue-gray-dark .mat-form-field.mat-focused .mat-form-field-label {
    color: #fff !important;
}

body.theme-blue-gray-dark .tbl_fix_header {
    background-color: #424242 !important;
}

body.theme-blue-gray-dark .mat-table {
    background: #424242 !important;
}
body.theme-blue-gray-dark .mat-card  {
    background: #424242 !important;
    color: white !important;
}
body.theme-blue-gray-dark.body.theme-yellow-light .mat-table {
    background: #424242 !important;
}

body.theme-blue-gray-dark .grid-overlay-msg {
    color: #fff;
}

body.theme-blue-gray-dark button.disabled-click {
    color: rgba(255, 255, 255, 0.4) !important;
}

// body.theme-blue-gray-dark .mat-flat-button.mat-primary,
// body.theme-blue-gray-dark .mat-raised-button.mat-primary {
//     background-color: #006400 !important;
// }
body.theme-blue-gray-dark .mat-flat-button.mat-accent,
body.theme-blue-gray-dark .mat-raised-button.mat-accent {
    background-color: #006400 !important;
}

body.theme-blue-gray-dark .add_edit_contact_main {
    background-color: #424242;
}

body.theme-blue-gray-dark .mat-dialog-container {
    background: transparent;
    box-shadow: none;
}

body.theme-blue-gray-dark .popup_main_div {
    background-color: #424242;
}

body.theme-blue-gray-dark .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
body.theme-blue-gray-dark .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: #006400;
}

body.theme-blue-gray-dark .example-list {
    background-color: #424242;
}

body.theme-blue-gray-dark .contact_right_action {
    background-color: #424242;
}

body.theme-blue-gray-dark .top_right_action_billing_one {
    background-color: #424242;
}

body.theme-blue-gray-dark .mat-tab-group.mat-primary .mat-ink-bar,
body.theme-blue-gray-dark .mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: #fff !important;
}

body.theme-blue-gray-dark .mail-content a {
    color: #fff;
}

body.theme-blue-gray-dark .cal-month-view .cal-day-cell.cal-today {
    background-color: rgba(0, 100, 0, 0.4) !important;
    ;
}

body.theme-blue-gray-dark .list_sidebar_padding {
    background-color: #424242;
    border-left: none;
}

body.theme-blue-gray-dark .favourite_form_div mat-list.mat-list.mat-list-base.ng-star-inserted {
    // background-color: transparent;
    background-color: #fff;
}

body.theme-blue-gray-dark .favourite_form_div .mat-list-item-content {
    color: #000;
}

body.theme-blue-gray-dark .mat-raised-button.mat-primary {
    background-color: #999999;
}

body.theme-blue-gray-dark button.cancel.mat-button[_ngcontent-app-c41] {
    border: 1px solid #999;
    background-color: #303540;
}

body.theme-blue-gray-dark toolbar fuse-sidebar.right-positioned {
    background: #303030 !important;
}

body.theme-blue-gray-dark .row-text-colore mat-cell {
    color: #000 !important;
}

body.theme-blue-gray-dark .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: #006400 !important;
}

body.theme-blue-gray-dark .mat-radio-button.mat-accent .mat-radio-inner-circle {
    background-color: #006400 !important;
}

body.theme-blue-gray-dark .total_Val {
    color: #fff;
}

body.theme-blue-gray-dark .mat-row.row-text-colore td {
    color: #000 !important
}

body.theme-blue-gray-dark .row-text-colore .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
body.theme-blue-gray-dark .row-text-colore .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: #fff !important;
}

body.theme-blue-gray-dark mat-row .special-group-header {
    color: #fff !important;
}

body.theme-blue-gray-dark .mat-list-base .mat-subheader {
    color: rgba(255, 255, 255, 0.7) !important;
}

body.theme-blue-gray-dark .row-text-colore .mat-typography p {
    color: #fff !important;
}

body.theme-blue-gray-dark .e-grid .e-gridcontent {
    background-color: #424242 !important;
}

body.theme-blue-gray-dark .e-grid .e-rowcell:not(.e-editedbatchcell),
body.theme-blue-gray-dark .e-grid .e-detailrowcollapse:not(.e-editedbatchcell),
body.theme-blue-gray-dark .e-grid .e-detailrowexpand:not(.e-editedbatchcell),
body.theme-blue-gray-dark .e-grid .e-gridcontent .e-rowdragdrop:not(.e-editedbatchcell),
body.theme-blue-gray-dark .e-grid .e-emptyrow:not(.e-editedbatchcell) {
    color: #fff;
}

body.theme-blue-gray-dark .e-treegrid .e-treegridexpand,
body.theme-blue-gray-dark .e-treegrid .e-treegridcollapse {
    color: #fff;
}

body.theme-blue-gray-dark .e-treegrid .e-active .e-treegridexpand,
body.theme-blue-gray-dark .e-treegrid .e-active .e-treegridcollapse {
    color: #000;
}

body.theme-blue-gray-dark .readonly_apply .mat-form-field-wrapper,
body.theme-blue-gray-dark .readonly_apply .mat-input {
    background-color: transparent;
    cursor: not-allowed;
}

body.theme-blue-gray-dark .disabled-colore[disabled],
body.theme-blue-gray-dark .disabled-colore:disabled {
    color: rgba(255, 255, 255, 0.4) !important;
}

body.theme-blue-gray-dark button.cancel.mat-button {
    border: 1px solid #999 !important;
    background-color: #777 !important;
}

body.theme-blue-gray-dark .border_div a {
    color: #006400 !important;
}

body.theme-blue-gray-dark fuse-sidebar {
    background: #303030 !important;
}

body.theme-blue-gray-dark .theme-options-panel form .group h2 {
    background: #303030 !important;
    color: rgba(255, 255, 255, 0.54) !important;
}

body.theme-blue-gray-dark .theme-options-panel .mat-radio-button {
    margin-left: 0 !important;
}

body.theme-blue-gray-dark .mat-form-field-appearance-outline .mat-form-field-outline-start,
body.theme-blue-gray-dark .mat-form-field-appearance-outline .mat-form-field-outline-gap,
body.theme-blue-gray-dark .mat-form-field-appearance-outline .mat-form-field-outline-end {
    border-color: #fff;
}

body.theme-blue-gray-dark .has-error.mat-form-field-appearance-outline .mat-form-field-outline-start,
body.theme-blue-gray-dark .has-error.mat-form-field-appearance-outline .mat-form-field-outline-gap,
body.theme-blue-gray-dark .has-error.mat-form-field-appearance-outline .mat-form-field-outline-end {
    border-color: red;
}

body.theme-blue-gray-dark .has-error.mat-form-field-appearance-outline.mat-form-field-should-float .mat-form-field-outline-gap,
body.theme-blue-gray-dark .has-error.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-gap {
    border-top-color: transparent;
}

body.theme-blue-gray-dark .mat-tab-body h3 {
    background-color: #303030 !important;
}

body.theme-blue-gray-dark .cal-month-view .cal-day-badge {
    background-color: #006400 !important;
}

body.theme-blue-gray-dark .cal-month-view .cal-days .cal-cell-row .cal-day-cell.cal-weekend .cal-day-number {
    color: #006400 !important;
}

body.theme-blue-gray-dark .cal-week-view {
    background-color: #424242 !important;
}

body.theme-blue-gray-dark .cal-week-view .cal-hour-odd {
    background-color: #303030 !important;
}

body.theme-blue-gray-dark .cal-week-view .cal-day-headers .cal-header.cal-weekend span {
    color: #006400 !important;
}

body.theme-blue-gray-dark a.link_click {
    color: #fff !important;
}

body.theme-blue-gray-dark .ngx-charts {
    fill: #fff !important;
}

body.theme-blue-gray-dark .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    color: #fff !important;
}

body.theme-blue-gray-dark .e-grid .e-headercell,
body.theme-blue-gray-dark .e-grid .e-detailheadercell {
    background-color: #424242 !important;
    color: #fff !important;
}

body.theme-blue-gray-dark .is_green.e-treegrid td.e-active {
    background-color: rgb(217, 217, 217) !important;
}

body.theme-blue-gray-dark .e-grid,
body.theme-blue-gray-dark .e-grid .e-content,
body.theme-blue-gray-dark .e-grid .e-table {
    background-color: #424242 !important;
}

// body.theme-blue-gray-dark .mat-slide-toggle-thumb {
//    // background-color: #43a047 !important;
//      background-color: #bdbdbd !important;
// }

// body.theme-blue-gray-dark .mat-slide-toggle-bar {
//    // background-color: rgba(67, 160, 71, 0.5) !important;
//      background-color: rgba(255, 255, 255, 0.5) !important;
// }
body.theme-blue-gray-dark .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: #43a047 !important;
}
body.theme-blue-gray-dark .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: rgba(67, 160, 71, 0.54) !important;
}

body.theme-blue-gray-dark .mat-progress-spinner circle,
body.theme-blue-gray-dark .mat-spinner circle {
    stroke: #fff !important;
}

body.theme-blue-gray-dark .theme-options-panel form .group {
    border: 1px solid rgba(255, 255, 255, 0.22) !important;
}

body.theme-blue-gray-dark .mat-dialog-container .mat-card:not([class*='mat-elevation-z']) {
    border: 1px solid #fff !important;
}

body.theme-blue-gray-dark .btm_email_margin .mat-stroked-button {
    border: 1px solid #006400 !important;
}

body.theme-blue-gray-dark .row-text-colore span {
    color: #000;
}

body.theme-blue-gray-dark .row-text-colore span.has-red {
    color: red;
}

body.theme-blue-gray-dark .cal-week-view .cal-time-events .cal-day-columns:not(.cal-resize-active) .cal-hour-segment:hover {
    background-color: #999 !important;
}

body.theme-blue-gray-dark .mat-calendar-body-selected {
    background-color: #006400 !important;
}

body.theme-blue-gray-dark .precedents-listing {
    background-color: #424242 !important;
}

body.theme-blue-gray-dark .mat-header-row .mat-header-cell:first-child {
    border-left: none !important;
}

body.theme-blue-gray-dark .mat-row .mat-cell:first-child {
    border-left: none !important;
}

body.theme-blue-gray-dark .precedents-header {
    // background-color: #006400 !important;
}

body.theme-blue-gray-dark .court-header {
    // background-color: #006400 !important;
}

img.seticon {
    margin-top: 0 !important;
    width: 20px !important;
    float: right !important;
}

.mat-form-field mat-icon.set_icon {
    font-size: 22px !important;
}

body.theme-blue-gray-dark .e-treegrid td.e-active label {
    color: #000;
}

body.theme-blue-gray-dark .ck.ck-editor__main>.ck-editor__editable {
    background: none !important;
}

body.theme-blue-gray-dark .readonly_apply .mat-form-field-label,
body.theme-blue-gray-dark .gray_out_for_poup .mat-form-field-label,
body.theme-blue-gray-dark .gray_out .mat-form-field-label {
    color: rgba(0, 0, 0, 0.7);
}

body.theme-blue-gray-dark .readonly_apply .mat-input-element,
body.theme-blue-gray-dark .gray_out_for_poup .mat-input-element,
body.theme-blue-gray-dark .gray_out .mat-input-element {
    color: rgba(0, 0, 0, 0.7);
}

body.theme-blue-gray-dark .search-wrapper .secondary-text {
    color: rgba(0, 0, 0, 0.7) !important;
}

body.theme-blue-gray-dark .settlment_height .table_overflow_auto {
    background-color: #424242 !important;
}

body.theme-blue-gray-dark .settlment_height.example-containerdata {
    background-color: #424242 !important;
}

body.theme-yellow-light .cal-month-view .cal-day-appoint .cal-day-appoint-left,
body.theme-blue-gray-dark .cal-month-view .cal-day-appoint .cal-day-appoint-left {
    background: #006400 !important;
}

body.theme-yellow-light .cal-month-view .cal-day-appoint .cal-day-appoint-right,
body.theme-blue-gray-dark .cal-month-view .cal-day-appoint .cal-day-appoint-right {
    background: rgba(0, 100, 0, 0.4) !important;
    // color: #fff !important;
}

body.theme-blue-gray-dark .row-text-colore td {
    color: #000;
}

body.theme-blue-gray-dark .mat-primary .mat-pseudo-checkbox-checked,
body.theme-blue-gray-dark .mat-primary .mat-pseudo-checkbox-indeterminate {
    background: #006400 !important;
}

body.theme-blue-gray-dark .mat-form-field.mat-focused .mat-form-field-ripple {
    background-color: #fff;
}

body.theme-blue-gray-dark .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
    color: rgba(0, 0, 0, 0.5) !important;
}

body.theme-blue-gray-dark .mat-input-element:disabled,
body.theme-blue-gray-dark .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
    color: rgba(0, 0, 0, 0.6) !important;
}

body.theme-blue-gray-dark .mat-icon-button[disabled][disabled] {
    color: rgba(0, 0, 0, 0.3) !important;
}

body.theme-blue-gray-dark .court-listing {
    background: #424242;
}

body.theme-blue-gray-dark .mat-badge-warn .mat-badge-content {
    background: #43a047;
}

body.theme-blue-gray-dark .mat-paginator-container .mat-icon-button[disabled][disabled] {
    color: rgba(255, 255, 255, 0.4) !important;
}

body.theme-blue-gray-dark .mat-select-disabled .mat-select-value {
    color: rgba(0, 0, 0, 0.5);
}

body.theme-blue-gray-dark .cal-event-actions a {
    color: #006400;
}

body.theme-blue-gray-dark .mat-progress-bar-fill::after {
    background-color: #006400 !important;
}

body.theme-blue-gray-dark .mat-progress-bar-buffer {
    background-color: #82b382 !important;
}

body.theme-blue-gray-dark .row-text-colore td {
    color: #000 !important;
}

body.theme-blue-gray-dark .mat-table .mat-header-cell {
    color: rgba(255, 255, 255, 0.7) !important;
    background-color: #424242 !important;
}

body.theme-blue-gray-dark .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
    color: #fff !important;
}

body.theme-blue-gray-dark .mat-table .mat-header-cell {
    color: rgba(255, 255, 255, 0.7) !important;
    background-color: #424242 !important;
}

body.theme-blue-gray-dark .right-chat-panel .primary {
    background-color: #006400 !important;
}

body.theme-blue-gray-dark .primary {
    background-color: #006400 !important;
}

body.theme-blue-gray-dark app-inbox .center .header .search-wrapper .sidebar-toggle {
    color: #555;
}

body.theme-blue-gray-dark .mat-datepicker-toggle-active {
    color: #fff !important;
}

body.theme-blue-gray-dark .sidebar-precedents,
body.theme-blue-gray-dark .sidebar-court {
    border: 1px solid #555 !important;
    background: #424242 !important;
}

body.theme-blue-gray-dark .key_subClass {
    color: #fff !important;
}

body.theme-blue-gray-dark .key_subClass:hover {
    background-color: #333;
}

body.theme-blue-gray-dark .mat-raised-button.mat-accent {
    background-color: #006400 !important;
}

body.theme-blue-gray-dark .e-float-input.e-control-wrapper:not(.e-input-focus):not(.e-disabled) input:not(:focus):not(:valid)~label.e-float-text:not(.e-label-top) {
    color: rgba(255,255,255,.7) !important;
    background: bottom !important;
}
body.theme-blue-gray-dark .e-input-group .e-input-group-icon,
body.theme-blue-gray-dark .e-input-group.e-control-wrapper .e-input-group-icon {
    border-color: rgba(255,255,255,.42) !important;
    color: rgba(255,255,255,.54) !important;
}
body.theme-blue-gray-dark .e-float-input.e-control-wrapper:not(.e-error) input[readonly]~label.e-label-top.e-float-text,
body.theme-blue-gray-dark .e-float-input.e-control-wrapper:not(.e-error) input~label.e-label-top.e-float-text,
body.theme-blue-gray-dark .e-float-input.e-control-wrapper:not(.e-error).e-input-focus input~label.e-float-text {
    background-color: #424242 !important;
}
body.theme-blue-gray-dark .e-float-input.e-control-wrapper:not(.e-error) input[readonly]~label.e-label-top.e-float-text,
body.theme-blue-gray-dark .e-float-input.e-control-wrapper:not(.e-error) input~label.e-label-top.e-float-text,
body.theme-blue-gray-dark .e-float-input.e-control-wrapper:not(.e-error).e-input-focus input~label.e-float-text {
    color: rgba(255,255,255,.7)!important;
    background-color: #303030 !important;
    // font-family: Muli,Helvetica Neue,Arial,sans-serif !important;
    font-family: 'Poppins', sans-serif !important;
}
body.theme-blue-gray-dark .e-float-input.e-control-wrapper.e-input-group.e-input-focus:not(.e-float-icon-left) {
    border: 2px solid #fff !important;
}

body.theme-blue-gray-dark .mat-dialog-container .e-float-input.e-control-wrapper:not(.e-error) input[readonly]~label.e-label-top.e-float-text,
body.theme-blue-gray-dark .mat-dialog-container .e-float-input.e-control-wrapper:not(.e-error) input~label.e-label-top.e-float-text,
body.theme-blue-gray-dark .mat-dialog-container .e-float-input.e-control-wrapper:not(.e-error).e-input-focus input~label.e-float-text {
    background-color: #424242 !important;
}
body.theme-blue-gray-dark .mat-dialog-container .e-float-input.e-control-wrapper:not(.e-error) input[readonly]~label.e-label-top.e-float-text,
body.theme-blue-gray-dark .mat-dialog-container .e-float-input.e-control-wrapper:not(.e-error) input~label.e-label-top.e-float-text,
body.theme-blue-gray-dark .mat-dialog-container .e-float-input.e-control-wrapper:not(.e-error).e-input-focus input~label.e-float-text {
    color: rgba(255,255,255,.7)!important;
    background-color: #424242 !important;
}
body.theme-blue-gray-dark .e-float-input input[readonly], body.theme-blue-gray-dark .e-float-input.e-control-wrapper input[readonly] {
    color: #fff !important;
}
body.theme-blue-gray-dark app-silqpay a {
    color: #139613 !important;
}
body.theme-blue-gray-dark .detail-mail-doc-name {
    color: rgba(255, 255, 255, 0.8);
}
body.theme-blue-gray-dark .attachment-list a {
    color: #139613 !important;
}

body.theme-blue-gray-dark .e-schedule .e-vertical-view .e-header-cells.e-current-day {
    color: #139613 !important;
}
body.theme-blue-gray-dark .e-schedule .e-vertical-view .e-clone-time-indicator,
body.theme-blue-gray-dark .e-schedule .e-vertical-view .e-current-time {
    color: #139613 !important;
}
body.theme-blue-gray-dark .e-schedule .e-vertical-view .e-current-timeline {
    border-top: 1px solid #139613 !important;
}
body.theme-blue-gray-dark .e-schedule .e-vertical-view .e-day-wrapper .e-appointment {
    background: #139613 !important;
    border: 1px solid #139613 !important;
}
body.theme-blue-gray-dark .e-schedule .e-vertical-view .e-previous-timeline {
    border-top: 1px dotted #139613 !important;
}
body.theme-blue-gray-dark .e-schedule .e-month-view .e-appointment {
    background: #139613 !important;
}

body.theme-blue-gray-dark .e-schedule .e-month-view .e-current-date .e-date-header,
body.theme-blue-gray-dark .e-schedule .e-month-agenda-view .e-current-date .e-date-header {
    background-color: #139613 !important;
}
body.theme-blue-gray-dark .e-schedule .e-month-view .e-date-header-wrap table td.e-current-day,
body.theme-blue-gray-dark .e-schedule .e-month-agenda-view .e-date-header-wrap table td.e-current-day {
    color: #139613 !important;
}
body.theme-blue-gray-dark .e-schedule .e-month-view .e-current-date {
    background-color: #c4e2c4 !important;
}
body.theme-blue-gray-dark .e-schedule .e-schedule-toolbar .e-active-view .e-tbar-btn-text,
body.theme-blue-gray-dark .e-schedule .e-schedule-toolbar .e-active-view .e-icons {
    color: #139613 !important;
}
body.theme-blue-gray-dark .upload_doc_header {
    background-color: #139613;
}
